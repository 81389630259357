import { Box, Button, Grid, Paper, TableCell, TableContainer, TableRow, TextField, Toolbar } from '@mui/material'
import React, {useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useStore } from '../../store/rootStore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';

interface CustomerState {
    name: string;
    phone_number: string;
    address: string;
}

const CustomerDetails = (props:any) => {
    const [searchText, setSearchText] = useState("");
    const [customer, setCustomer] = useState<CustomerState>(
        {
        name: "",
        phone_number: "",
        address: ""
        }
    );
    const {rootStore: {customerStore, authStore}} = useStore();
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    
    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await customerStore.getCustomerDetails(props.id);
            setCustomer(resData.data.customer);
            console.log(resData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        setSearchText(event.target.value);

        customerStore.detailsPaymentRowData = customerStore.searchDetailsPaymentData.filter((row)=>{
        return row.name.toLowerCase().includes(value)
            || row.phone_number.toLowerCase().includes(value)
            || row.address.toLowerCase().includes(value)
            || row.type.toLowerCase().includes(value)
            || row.status.toLowerCase().includes(value)
            || row.amount.replace(/,/g, '').includes(value)
            || row.date.toLowerCase().includes(value)
            || row.time.toLowerCase().includes(value);
        })
    };

    const getButtonColor = (status:any) => {
        switch (status) {
        case "Approve":
            return "success"; // Green button
        case "Pending":
            return "warning"; // Yellow button
        default:
            return "primary"; // Default blue
        }
    };


    return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Grid item xs={6}>
    <TableContainer component={Paper}>
        <TableRow>
        <TableCell sx={{padding:'8px 15px' }}>{t('name')}</TableCell>
        <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
        <TableCell sx={{padding:'8px 15px' }}>{customer.name}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell sx={{padding:'8px 15px' }}>{t('phone')}</TableCell>
        <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
        <TableCell sx={{padding:'8px 15px' }}>
        {customer.phone_number}
        </TableCell>
        </TableRow>
        <TableRow>
        <TableCell sx={{padding:'8px 15px' }}>{t('address')}</TableCell>
        <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
        <TableCell sx={{padding:'8px 15px' }}>{customer.address}</TableCell>
        </TableRow>
    </TableContainer>
    </Grid>
    <Toolbar style={{padding: 0, justifyContent:'space-between', alignItems:'center'}}>
        <Grid container alignItems="center" justifyContent={'space-between'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={t('search')}
              value={searchText}
              onChange={handleSearch}
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
            />
          </Grid>
        </Grid>
    </Toolbar>
      <DataGrid
        rows={customerStore.detailsPaymentRowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 20,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              {field: 'name', headerName: t('name'), width:150},
              {field: 'phone_number', headerName: t('phone'), width:150},
              {field: 'address', headerName: t('address'), width:200},
              {field: 'type', headerName: t('customerPaymenttype'), width:150, align:'center'},
              {field: 'amount', headerName: t('customerPaymentAmount'), width:150, align:'right'},
              {
                field: 'status',
                headerName: t('status'),
                align:'center', width:100,
                renderCell: (params) => (
                  <>
                  {authStore.role==='Admin' ? 
                  <Button
                    variant="contained"
                    size="small"
                    color={getButtonColor(params.value)}
                  >
                    {params.value}
                  </Button> : 
                  <Button
                    variant="contained"
                    size="small"
                    color={getButtonColor(params.value)}
                  >
                    {params.value}
                  </Button>}
                  </>
                ),
              },
              {field: 'date', headerName: t('date'), width:100},
              {field: 'time', headerName: t('time'), width:100}
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
      />
    </>
    )
}

export default observer(CustomerDetails);