import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Login from "../pages/guest/Login";
import Customers from "../pages/private/customers";
import BaseLayout from "../components/layouts/BaseLayout";
import PrivateRoute from "../middleware/PrivateRoute";
import Dashboard from "../pages/private/dashboard";
import CustomerList from "../pages/private/customers/list";
import CustomerCreate from "../pages/private/customers/create";
import CustomerEdit from "../pages/private/customers/edit";
import Suppliers from "../pages/private/suppliers";
import SupplierList from "../pages/private/suppliers/list";
import SupplierCreate from "../pages/private/suppliers/create";
import SupplierEdit from "../pages/private/suppliers/edit";
import Products from "../pages/private/products";
import ProductList from "../pages/private/products/list";
import ProductCreate from "../pages/private/products/create";
import ProductEdit from "../pages/private/products/edit";
import Stocks from "../pages/private/stocks";
import StockList from "../pages/private/stocks/list";
import StockCreate from "../pages/private/stocks/create";
import StockEdit from "../pages/private/stocks/edit";
import Sales from "../pages/private/sales";
import SaleList from "../pages/private/sales/list";
import SaleCreate from "../pages/private/sales/create";
import SaleView from "../pages/private/sales/view";
import Settings from "../pages/private/settings";
import ProductCategory from "../pages/private/settings/productCategory";
import ProductUnit from "../pages/private/settings/productUnit";
import Users from "../pages/private/settings/users";
import Unauthorized from "../pages/guest/unauthorized";
import NotFoundPage from "../pages/guest/404"
import Profile from "../pages/private/profile";
import ProfileEdit from "../pages/private/profileEdit";
import Purchases from "../pages/private/purchases";
import PurchaseList from "../pages/private/purchases/list";
import PurchaseCreate from "../pages/private/purchases/create";
import PurchaseView from "../pages/private/purchases/view";
import CustomerPaymentList from "../pages/private/customers/payments";
import CustomerPaymentCreate from "../pages/private/customers/newPayment";
import CustomerPreDueList from "../pages/private/customers/predue";
import CustomerPreDueCreate from "../pages/private/customers/newPreDue";
import CustomerPreAdvanceList from "../pages/private/customers/preAdvance";
import CustomerPreAdvanceCreate from "../pages/private/customers/newPreAdvance";
import SupplierPaymentList from "../pages/private/suppliers/payments";
import SupplierPaymentCreate from "../pages/private/suppliers/newPayment";
import Reports from "../pages/private/reports";
import DailyCustomerReprt from "../pages/private/reports/dailyCustomerReport";
import DailySupplierReprt from "../pages/private/reports/dailySupplierReport";
import CustomerDueReprt from "../pages/private/reports/customerDueReport";


const routes = [
    {
        path: "/", element: <Navigate to={"login"}/>
    },
    {
        path: "/login", element: <Login/>
    },
    {
        path: "/unauthorized", element: <Unauthorized/>
    },
    {
        path: "/app", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<BaseLayout/>} />, children: [
            {path: "dashboard", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Dashboard/>} />},
            {path: "sales", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Sales/>} />, children: [
                {path: "", element: <SaleList/>},
                {path: "create", element: <SaleCreate/>},
                {path: "view/:id", element: <SaleView/>}
            ]},
            {path: "purchases", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Purchases/>} />, children: [
                {path: "", element: <PurchaseList/>},
                {path: "create", element: <PurchaseCreate/>},
                {path: "view/:id", element: <PurchaseView/>}
            ]},
            {path: "customers", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Customers/>} />, children: [
                {path: "", element: <CustomerList/>},
                {path: "create", element: <CustomerCreate/>},
                {path: "edit/:id", element: <CustomerEdit/>},
                {path: "payments", element: <CustomerPaymentList/>},
                {path: "payments/create", element: <CustomerPaymentCreate/>},
                {path: "predue", element: <CustomerPreDueList/>},
                {path: "predue/create", element: <CustomerPreDueCreate/>},
                {path: "preAdvance", element: <CustomerPreAdvanceList/>},
                {path: "preAdvance/create", element: <CustomerPreAdvanceCreate/>},
            ]},
            {path: "suppliers", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Suppliers/>} />, children: [
                {path: "", element: <SupplierList/>},
                {path: "create", element: <SupplierCreate/>},
                {path: "edit/:id", element: <SupplierEdit/>},
                {path: "payments", element: <SupplierPaymentList/>},
                {path: "payments/create", element: <SupplierPaymentCreate/>},
            ]},
            {path: "products", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Products/>} />, children: [
                {path: "", element: <ProductList/>},
                {path: "create", element: <ProductCreate/>},
                {path: "edit/:id", element: <ProductEdit/>}
            ]},
            {path: "stocks", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Stocks/>} />, children: [
                {path: "", element: <StockList/>},
                {path: "create", element: <StockCreate/>},
                {path: "edit/:id", element: <StockEdit/>}
            ]},
            {path: "reports", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Reports/>} />, children: [
                {path: "daily_customer_report", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<DailyCustomerReprt/>} />},
                {path: "daily_supplier_report", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<DailySupplierReprt/>} />},
                {path: "customer_due_report", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<CustomerDueReprt/>} />},
            ]},
            {path: "settings", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Settings/>} />, children: [
                {path: "product_category", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<ProductCategory/>} />},
                {path: "units", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<ProductUnit/>} />},
                {path: "users", element: <PrivateRoute roles={["Admin"]} element={<Users/>} />},
            ]},
            {path: "profile", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<Profile/>} />},
            {path: "profile/edit", element: <PrivateRoute roles={["Admin","Manager","Salesman"]} element={<ProfileEdit/>} />},
            
        ]
    },
    {
        path: "*", element: <NotFoundPage/>
    },
]

const AppRoutes = () => {

    const route = useRoutes(routes);

    return route;
}


export default AppRoutes;
