import { Box, Button, Toolbar } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useStore } from "../../store/rootStore";
import { Grid, Container, TextField } from '@mui/material';
import InfoCard from "../../components/card/InfoCard";
import LinearProgress from '@mui/material/LinearProgress';
import DailyBarChart from "../../components/Charts/dailyBarChart";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DatePicker from 'react-datepicker';
import CachedIcon from '@mui/icons-material/Cached';


const Dashboard = () => {

    const [t, i18n] = useTranslation("global");
    const [searchText, setSearchText] = useState("");
    const { rootStore: { authStore, dashboardStore } } = useStore();
    const [loading, setLoading] = useState(false);
    const [dailyBarCharts, setDailyBarCharts] = useState([]);
    const [updateId, setUpdateId] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [message, setMessage] = useState('');

    const initTable = async () => {
        setLoading(true);
        try {
            const date = new Date();
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            const resData = await dashboardStore.fetchDashboardDetails(formattedDate);
            setDailyBarCharts(resData.data.dailyBarCarts);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    
    const initCustomerDueTable = async () => {
        setLoading(true);
        try {
            const date = new Date();
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            const resData = await dashboardStore.fetchCustomerDueReportList(formattedDate);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const handleUpdateDialogClose = () => {
          
        setDialogOpen(false);
    };

    const handleUpdateOpenDialog = () => {
        
        setDialogOpen(true);
    }

    const validationSchema = Yup.object().shape({
      date: Yup.date()
      .nullable()
      .required('Date is required')
      .typeError('Invalid date format')
      .transform((value, originalValue) => {
        return originalValue === '' ? null : new Date(originalValue);
      }),
    })

    const { control, handleSubmit, formState: { errors }, reset, setError } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
          date: new Date()
        }
    })

    const onSubmit = async (data:any) => {
        setLoading(true);
        try {
            const date = new Date(data.date);
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            // console.log(formattedDate);
            const resData = await dashboardStore.fetchCustomerDueReportList(formattedDate);
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
            setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
            });
            })
            setLoading(false);
        }
      }

    const updateValidationSchema = Yup.object().shape({
        id: Yup.string(),
        date: Yup.date()
        .nullable()
        .required('Date is required')
        .typeError('Invalid date format')
        .transform((value, originalValue) => {
          return originalValue === '' ? null : new Date(originalValue);
        }),
    })

    const { control:updateControl, handleSubmit:updateHandleSubmit, formState: { errors:updateErrors }, reset:updateReset, setValue:updateSetValue, setError:updateSetError } = useForm({
        resolver: yupResolver(updateValidationSchema),
        defaultValues: {
            id: "",
            date: new Date()
        }
    })


    const editData = async(params:any) => {
        updateReset({date:new Date()});
        setUpdateId(params.id);
        handleUpdateOpenDialog();
        setLoading(true);
        try {
            updateSetValue('id', params.id);
            setLoading(false);
        } catch (error) {
            // Handle errors, e.g., show an error message
            setLoading(false);
            console.error('Error while fetching data:', error);
        }
  
        console.log(params.id);
  
      }

    const onUpdateCustomerDueSubmit = async (data:any) => {
      setLoading(true);
      try {
          if(updateId !== null){
              const resData = await dashboardStore.updateDueDate(data)
              // console.log(resData);
              updateReset({id:'',date:new Date()});
              handleUpdateDialogClose();
              initCustomerDueTable();
          }
          setLoading(false);
      } catch (error:any) {
          Object.keys(error?.data).map((e:any) => {
          updateSetError(e, {
              type: 'manual', // Use 'manual' for manually triggered errors
              message: error?.data[e],
          });
          })
          setLoading(false);
      }
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        setSearchText(event.target.value);
  
        dashboardStore.customerDueReportRowData = dashboardStore.searchCustomerDueReportData.filter((row)=>{
          return row.name.toLowerCase().includes(value)
           || row.phone_number.toLowerCase().includes(value)
           || row.address.toLowerCase().includes(value)
           || (row.purchase||"").replace(/,/g, '').includes(value)
           || (row.payment||"").replace(/,/g, '').includes(value)
           || (row.due_payment||"").replace(/,/g, '').includes(value)
           || (row.advance_payment||"").replace(/,/g, '').includes(value)
           || (row.totalDue||"").replace(/,/g, '').includes(value)
           || row.lastPaymentDate.toLowerCase().includes(value);
        })
      };

    const refresh = async() => {
        setLoading(true);
        try {
            const date = new Date();
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            const resData = await dashboardStore.fetchCustomerDueReportList(formattedDate);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
        initCustomerDueTable();
        // console.log(authStore.role)
    },[])

    return (
        <>
            {loading ? 
            <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
                <LinearProgress color="secondary"/>
            </Box>
            : ''
            }
            <Dialog open={dialogOpen} onClose={handleUpdateDialogClose}
                sx={{
                    '& .MuiDialog-paper': {
                    width: '80%',            // Custom width
                    maxWidth: '500px',       // Custom max-width
                    height:'70%'
                    },
                }}
                >
                <DialogTitle>{t('updateLastPaymentDate')}</DialogTitle>
                    <form onSubmit={updateHandleSubmit(onUpdateCustomerDueSubmit)}>
                    <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
                    <Grid item xs={12}>
                    <Controller
                        control={updateControl}
                        name="date"
                        render={({ field }) => (
                            <DatePicker
                            placeholderText="Select a date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            dateFormat="dd-MM-yyyy"
                            customInput={
                                <TextField
                                variant="outlined"
                                fullWidth
                                sx={{
                                    margin: 0, // Outer margin
                                    '& .MuiInputBase-root': {
                                    height: '40px', // Input height
                                    padding: '0 0px', // Input padding
                                    fontSize: '14px',
                                    width:'100%'
                                    },
                                }}
                                />
                            }
                            />
                        )}
                        />
                        {updateErrors.date && <p style={{ color: 'red' }}>{updateErrors.date.message}</p>}
                    </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions style={{position:'absolute',width:'100%',bottom:0}}>
                    <Button onClick={handleUpdateDialogClose}>
                        {t('cancel')}
                    </Button>
                    <Button color="primary" type="submit">
                        {t('update')}
                    </Button>
                    </DialogActions>
                    </form>
                </Dialog>
            <h2>{t('dashboard.title')}</h2>
            <Grid container spacing={2} padding={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todayPurchase')} amount={dashboardStore.dashboardData.purchase} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todaySales')} amount={dashboardStore.dashboardData.sales} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todayCustomerPayment')} amount={dashboardStore.dashboardData.customerPayment} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todaySupplierPayment')} amount={dashboardStore.dashboardData.supplierPayment} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todayExtraIncome')} amount={dashboardStore.dashboardData.extraIncome} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todayExtraExpense')} amount={dashboardStore.dashboardData.extraIncome} backgroundColor="#d9edf3" />
                </Grid>
            </Grid>
            <Container>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={12}>
                        <DailyBarChart data={dailyBarCharts} />
                    </Grid>
                </Grid>
            </Container>
            <Container sx={{mt:2}}>
            <Box sx={{ height: 400, width: '100%' }}>
            <Toolbar style={{padding: 0, justifyContent:'space-between', alignItems:'center'}}>
                <TextField
                variant="outlined"
                label={t('search')}
                value={searchText}
                onChange={handleSearch}
                sx={{
                    margin: 0, // Outer margin
                    '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    padding: '0 0px', // Input padding
                    fontSize: '14px'
                    },
                    '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                    },
                }}
                />
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                        <Controller
                        control={control}
                        name="date"
                        render={({ field }) => (
                            <DatePicker
                            placeholderText="Select a date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            dateFormat="dd-MM-yyyy"
                            customInput={
                                <TextField
                                variant="outlined"
                                fullWidth
                                sx={{
                                    margin: 0, // Outer margin
                                    '& .MuiInputBase-root': {
                                    height: '40px', // Input height
                                    padding: '0 0px', // Input padding
                                    fontSize: '14px'
                                    },
                                }}
                                />
                            }
                            />
                        )}
                        />
                        {errors.date && <p style={{ color: 'red' }}>{errors.date.message}</p>}
                        </Grid>
                        <Grid item xs={1}>
                        <Button sx={{ ml: .01, mr:0 }} type="submit" variant="contained" color="success">
                            {t('search')}
                        </Button>
                        </Grid>
                    </Grid>
                </form>
                <Button sx={{ ml: .01, mr:0 }} type="button" onClick={refresh} variant="contained" color="success">
                <CachedIcon/>
                </Button>
            </Toolbar>
                <DataGrid
                    rows={dashboardStore.customerDueReportRowData}
                    columns={
                    [
                        {
                        field: "id",
                        headerName: "#",
                        filterable: false,
                        width: 50,
                        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
                        },
                        {field: 'name', headerName: t('name'), flex:1},
                        {field: 'phone_number', headerName: t('phone'), flex:1.05},
                        {field: 'address', headerName: t('address'), flex:1},
                        {field: 'purchase', headerName: t('purchasesAmount'), flex:1, align:'right'},
                        {field: 'payment', headerName: t('cashPayment'), flex:1, align:'right'},
                        {field: 'due_payment', headerName: t('duePayment'), flex:1, align:'right'},
                        {field: 'advance_payment', headerName: t('advancePayment'), flex:1, align:'right'},
                        {field: 'totalDue', headerName: t('totalDue'), flex:1, align:'right'},
                        {field: 'lastPaymentDate', headerName: t('lastPaymentDate'), flex:1, align:'right'},
                        {
                            field: 'actions',
                            headerName: t('action'),
                            width: 70,
                            sortable: false,
                            filterable: false,
                            renderCell: (params) => (
                                <>
                                    {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>editData(params)}><EditIcon/></Button>:""}
                                </>
                            )
                        }
                    ]
                    }
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5, 25, 100]}
                />
            </Box>
            </Container>
        </>
    )
}

export default observer(Dashboard);