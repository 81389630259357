import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, FormControlLabel, Checkbox, Button, CardMedia, Card, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import ServerSideCustomerAutocomplete from "../../../components/ui/ServerSideAutocomplete/ServerSideCustomerAutocomplete";
import AddNewItemForm from "./addNewItemForm";
import AllItemsList from "./allItemsList";
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const SaleCreate = () => {

    const { rootStore: { saleStore, customerStore } } = useStore();
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [payment, setPayment] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        customer: Yup.object().shape({
            id: Yup.string().required('Customer is required'),
            label: Yup.string().required('Customer is required'),
        }).required('Customer is required'),
        total_purchase: Yup.number(),
        total_payment: Yup.number(),
        total_due: Yup.number(),
        total_advance: Yup.number(),
    });

    const hookFormObj = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            customer: {id: "", label: ""},
            total_purchase: 0,
            total_payment: 0,
            total_due: 0,
            total_advance: 0,
        }
    })

    const { control, handleSubmit, formState: {errors}, reset, setValue, getValues, setError } = hookFormObj
    const [productsErrorMessage, setProductsErrorMessage] = useState<any>(null);

    const customerValidationSchema = Yup.object().shape({
        name: Yup.string().required(t('formValidation.name')),
        phone_number: Yup.string().required(t('formValidation.phone_number'))
          .min(11, t('formValidation.phone_len'))
          .max(11, t('formValidation.phone_len')),
        address: Yup.string().required(t('formValidation.address')),
      })

    const { control:customerControl, handleSubmit:customerSubmit, formState: { errors:customerError }, reset:customerReset, setError:customerSetError } = useForm({
        resolver: yupResolver(customerValidationSchema),
        defaultValues: {
            name: "",
            phone_number: "",
            address: "",
        }
    })

    const onSubmit = async (data:any) => {
        // console.log(data);
        
        setLoading(true);
        try {
            setProductsErrorMessage(null);
            const resData = await saleStore.createData(data)
            // console.log(resData);
            
            if (resData){
              reset({
                customer: {id: "", label: ""},
              })
              saleStore.setCartItems([]);
              saleStore.cartTotal = 0;
              saleStore.cartSubTotal = 0;
              saleStore.cartDue = 0;
              saleStore.cartDiscount = 0;
              saleStore.cartPayment = 0;
              saleStore.calculateDue = 0;
              saleStore.calculateAdvance = 0;
              saleStore.setcartAditionalInputValues(0,0);
              setDiscount(0);
              setPayment(0);
            //   console.log(resData);
              
              navigate('..')
            }
            
             
           setLoading(false);
          } catch (error:any) {
            console.log('Errors');
            setLoading(false);
            Object.keys(error?.data).map((e:any) => {
              setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
              });
            })
            setProductsErrorMessage("Please select one products");
          }
        
    }

    const onCustomerSubmit = async (data:any) => {
        setLoading(true);
        try {
            const resData = await customerStore.createData(data)
            if (resData){
            customerReset()
            console.log(resData);
            const object = {id:resData.data.customer.id, label:resData.data.customer.name, phone_number:resData.data.customer.phone_number, address:resData.data.customer.address}
            setValue('customer', object);
            setValue('total_purchase', 0);  
            setValue('total_payment', 0);  
            setValue('total_due', 0);  
            setValue('total_advance', 0); 
            handleNewCustomerDialogClose();
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
            setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
            });
            })
            setLoading(false);
        }
    }
    

    const handleSelectCustomer = (value: any) => {
        console.log("handleSelectCustomer ", value)
        const totalPayment = parseFloat(value?.payment) + parseFloat(value?.due_payment) + parseFloat(value?.advance_payment);
        const totalDue = parseFloat(value?.purchase) - Number(totalPayment);
        let advance = 0;
        let due = 0;
        if(totalDue < 0){
            advance = Math.abs(totalDue);
        }else if(isNaN(totalDue)){
            due = 0;
        }
        else if(totalDue > 0){
            due = totalDue;
        }
        else{
            due = 0;
        }
        // console.log(totalDue);
        // console.log(due);
        // console.log(advance);
        setValue('customer', value);
        setValue('total_purchase', value?.purchase);  
        setValue('total_payment', isNaN(totalPayment)?0:totalPayment);
        setValue('total_due', due);  
        setValue('total_advance', advance); 
        saleStore.customerDue = due; 
        saleStore.customerAdvance = advance;
        
        if(saleStore.customerAdvance > 0){
            let calDue = saleStore.subTotal(saleStore.cartItems) - saleStore.customerAdvance;
            if(calDue > 0){
                saleStore.calculateDue = calDue;
            }else{
                saleStore.calculateAdvance = Math.abs(calDue);
            }
        }else{
            let calDue = saleStore.subTotal(saleStore.cartItems) + saleStore.customerDue;
            saleStore.calculateDue = calDue;
            saleStore.calculateAdvance = advance;
        }
    };
    
    const handleNewCustomerDialogClose = () => {
        setDialogOpen(false);
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    }

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        <Dialog open={dialogOpen} onClose={handleNewCustomerDialogClose}>
            <DialogTitle>{t('customers.create')}</DialogTitle>
            <form onSubmit={customerSubmit(onCustomerSubmit)}>
            <DialogContent>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
                <Controller
                name="name"
                control={customerControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="name"
                    label={t('name')}
                    variant="filled"
                    error={!!customerError.name}
                    helperText={customerError.name?.message}
                    autoComplete="off"
                    size="small"
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                name="phone_number"
                control={customerControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="phone_number"
                    label={t("phone")}
                    variant="filled"
                    error={!!customerError.phone_number}
                    helperText={customerError.phone_number?.message}
                    autoComplete="off"
                    size="small"
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                name="address"
                control={customerControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="address"
                    label={t("address")}
                    variant="filled"
                    error={!!customerError.address}
                    helperText={customerError.address?.message}
                    autoComplete="off"
                    size="small"
                    />
                )}
                />
            </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleNewCustomerDialogClose}>
                {t('cancel')}
            </Button>
            <Button color="primary" type="submit">
                {t('save')}
            </Button>
            </DialogActions>
            </form>
        </Dialog>
        <Box sx={{ width: '100%' }}>
            <h4 style={{marginTop:0}}>{t('sales.create')}</h4>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4.7}>
                <Controller
                    name="customer"
                    control={control}
                    render={({ field }) => (
                    <ServerSideCustomerAutocomplete
                        label={t('selectCustomer')}
                        ajaxCallFn={customerStore.getList} 
                        addNewFn={handleOpenDialog}
                        onOptionSelect={(option) => {
                            field.onChange(option)
                            handleSelectCustomer(option)
                            if (option?.isNew) {
                                reset({
                                    customer: {id: "", label: ""},
                                })
                                setDialogOpen(true);
                            }

                        }}
                        error={errors.customer?.id ?? errors.customer}
                        field={field}
                    />
                    )}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_purchase"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_purchase"
                        label={t('totalPurchase')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_purchase}
                        helperText={errors.total_purchase?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_payment"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_payment"
                        label={t('totalPayment')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_payment}
                        helperText={errors.total_payment?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_due"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_due"
                        label={t('totalDue')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_due}
                        helperText={errors.total_due?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_advance"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_advance"
                        label={t('totalAdvance')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_advance}
                        helperText={errors.total_advance?.message}
                        />
                    )}
                    />
                </Grid>
            </Grid>
            </form>
            {/* Add new item form */}
            <AddNewItemForm/>
            {productsErrorMessage ?  <Box sx={{ color: 'error.main', my: 2 }}>{productsErrorMessage}</Box>: ""}
            <AllItemsList editMode={true} />
            <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
            </Button>
            <Button sx={{ mt: 2, ml: 2 }} type="button" variant="contained" color="success" onClick={() => handleSubmit(onSubmit)()}>
                {t('save')}
            </Button>
        </Box>
        </>
    )
    
}

export default observer(SaleCreate);