import PersonIcon from '@mui/icons-material/Person';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ListIcon from '@mui/icons-material/List';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import CategoryIcon from '@mui/icons-material/Category';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import PeopleIcon from '@mui/icons-material/People';
import MoneyIcon from '@mui/icons-material/Money';
import SummarizeIcon from '@mui/icons-material/Summarize';

export const menu = [
    {
        label: "Dashboard",
        text: "dashboard",
        link: "/app/dashboard",
        icon: <DashboardIcon fontSize="small"/>,
        roles:["Admin","Manager","Salesman"],
        submenu: [],
    },
    {
        label: "Sales",
        text: "sales",
        link: "/app/sales",
        icon: <ShoppingCartCheckoutIcon fontSize="small"/>,
        roles:["Admin","Manager","Salesman"],
        submenu: [
            {
                label: "Sales List",
                text: "salesList",
                link: "/app/sales",
                icon: <ListIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Sales New",
                text: "salesNew",
                link: "/app/sales/create",
                icon: <ShoppingCartCheckoutIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Sales Return List",
                text: "salesReturnList",
                link: "/app/salesreturn",
                icon: <ListIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Sales Return",
                text: "salesReturn",
                link: "/app/salesreturn/create",
                icon: <AddShoppingCartIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            }
        ]
    },
    {
        label: "Purchases",
        text: "purchases",
        link: "/app/purchases",
        icon: <AddShoppingCartIcon fontSize="small"/>,
        roles:["Admin","Manager","Salesman"],
        submenu: [
            {
                label: "Purchases List",
                text: "purchasesList",
                link: "/app/purchases",
                icon: <ListIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Purchases New",
                text: "purchasesNew",
                link: "/app/purchases/create",
                icon: <AddShoppingCartIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Purchases Return List",
                text: "purchasesReturnList",
                link: "/app/purchasesreturn",
                icon: <ListIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Purchases Return",
                text: "purchasesReturn",
                link: "/app/purchasesreturn/create",
                icon: <ShoppingCartCheckoutIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            }
        ]
    },
    {
        label: "Customer",
        text: "customer",
        link: "/app/customers",
        icon: <PersonIcon fontSize="small"/>,
        roles:["Admin","Manager","Salesman"],
        submenu: [
            {
                label: "List",
                text: "list",
                link: "/app/customers",
                icon: <ListIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Add New",
                text: "addNew",
                link: "/app/customers/create",
                icon: <PersonAddIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Payment",
                text: "customerPayment",
                link: "/app/customers/payments",
                icon: <MoneyIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Opening Due",
                text: "openingDue",
                link: "/app/customers/predue",
                icon: <MoneyIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Opening Advamce",
                text: "openingAdvance",
                link: "/app/customers/preAdvance",
                icon: <MoneyIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            }
        ]
    },
    {
        label: "Supplier",
        text: "supplier",
        link: "/app/suppliers",
        icon: <PersonIcon fontSize="small"/>,
        roles:["Admin","Manager","Salesman"],
        submenu: [
            {
                label: "List",
                text: "list",
                link: "/app/suppliers",
                icon: <ListIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Add New",
                text: "addNew",
                link: "/app/suppliers/create",
                icon: <PersonAddIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Payment",
                text: "supplierPayment",
                link: "/app/suppliers/payments",
                icon: <MoneyIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            }
        ]
    },
    {
        label: "Product",
        text: "product",
        link: "/app/products",
        icon: <Inventory2Icon fontSize="small"/>,
        roles:["Admin","Manager","Salesman"],
        submenu: [
            {
                label: "List",
                text: "list",
                link: "/app/products",
                icon: <ListIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Add New",
                text: "addNew",
                link: "/app/products/create",
                icon: <PersonAddIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            }
        ]
    },
    {
        label: "Stock",
        text: "stock",
        link: "/app/stocks",
        icon: <AccountBalanceIcon fontSize="small"/>,
        roles:["Admin","Manager","Salesman"],
        submenu: [
            {
                label: "List",
                text: "list",
                link: "/app/stocks",
                icon: <ListIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Add New",
                text: "addNew",
                link: "/app/stocks/create",
                icon: <PersonAddIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            }
        ]
    },
    {
        label: "Reports",
        text: "reports",
        link: "/app/reports",
        icon: <SummarizeIcon fontSize="small"/>,
        roles:["Admin","Manager","Salesman"],
        submenu: [
            {
                label: "Daily Customer Report",
                text: "dailyCustomerReport",
                link: "/app/reports/daily_customer_report",
                icon: <SummarizeIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Date Wise Customer Report",
                text: "dateWiseCustomerReport",
                link: "/app/reports/date_wise_customer_report",
                icon: <SummarizeIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Daily Supplier Report",
                text: "dailySupplierReport",
                link: "/app/reports/daily_supplier_report",
                icon: <SummarizeIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Date Wise Supplier Report",
                text: "dateWiseSupplierReport",
                link: "/app/reports/dateWise_supplier_report",
                icon: <SummarizeIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Customer Due Report",
                text: "customerDueReport",
                link: "/app/reports/customer_due_report",
                icon: <SummarizeIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            }
        ]
    },
    {
        label: "Settings",
        text: "settings",
        link: "/app/settings",
        icon: <SettingsIcon fontSize="small"/>,
        roles:["Admin","Manager","Salesman"],
        submenu: [
            {
                label: "Product Category",
                text: "productCategory",
                link: "/app/settings/product_category",
                icon: <CategoryIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Units",
                text: "units",
                link: "/app/settings/units",
                icon: <AcUnitIcon fontSize="small"/>,
                roles:["Admin","Manager","Salesman"],
            },
            {
                label: "Users",
                text: "users",
                link: "/app/settings/users",
                icon: <PeopleIcon fontSize="small"/>,
                roles:["Admin"],
            }
        ]
    }
]