import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Grid, TextField, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form';

const DailyCustomerReprt = () => {

    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");

    const {rootStore: {reportStore}} = useStore();

    const validationSchema = Yup.object().shape({
      date: Yup.date()
      .nullable()
      .required('Date is required')
      .typeError('Invalid date format')
      .transform((value, originalValue) => {
        return originalValue === '' ? null : new Date(originalValue);
      }),
    })

    const { control, handleSubmit, formState: { errors }, reset, setError } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
          date: new Date()
        }
    })

    const initTable = async () => {
        setLoading(true);
        try {
            const date = new Date();
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            const resData = await reportStore.fetchDailyCustomerReportList(formattedDate);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const onSubmit = async (data:any) => {
      setLoading(true);
      try {
          const date = new Date(data.date);
          const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
          // console.log(formattedDate);
          const resData = await reportStore.fetchDailyCustomerReportList(formattedDate);
          setLoading(false);
      } catch (error:any) {
          Object.keys(error?.data).map((e:any) => {
          setError(e, {
              type: 'manual', // Use 'manual' for manually triggered errors
              message: error?.data[e],
          });
          })
          setLoading(false);
      }
    }

    useEffect(()=>{
        initTable();
    }, []);


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      reportStore.customerDailyReportRowData = reportStore.searchCustomerDailyReportData.filter((row)=>{
        return row.name.toLowerCase().includes(value)
         || row.phone.toLowerCase().includes(value)
         || row.address.toLowerCase().includes(value)
         || (row.salesAount||"").replace(/,/g, '').includes(value)
         || (row.cashPayment||"").replace(/,/g, '').includes(value)
         || (row.duePayment||"").replace(/,/g, '').includes(value)
         || (row.advancePayment||"").replace(/,/g, '').includes(value);
      })
    };

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Box sx={{ height: 400, width: '100%' }}>
    <h4>{t('menu.dailyCustomerReport')}</h4>
      <Toolbar style={{padding: 0, justifyContent:'space-between', alignItems:'center'}}>
        <Grid container alignItems="center" justifyContent={'space-between'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={t('search')}
              value={searchText}
              onChange={handleSearch}
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container alignItems="center" justifyContent={'flex-end'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="Select a date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        dateFormat="dd-MM-yyyy"
                        customInput={
                          <TextField
                            variant="outlined"
                            fullWidth
                            sx={{
                              margin: 0, // Outer margin
                              '& .MuiInputBase-root': {
                                height: '40px', // Input height
                                padding: '0 0px', // Input padding
                                fontSize: '14px'
                              },
                            }}
                          />
                        }
                      />
                    )}
                  />
                  {errors.date && <p style={{ color: 'red' }}>{errors.date.message}</p>}
                </Grid>
                <Grid item xs={2}>
                  <Button sx={{ ml: .01, mr:0 }} type="submit" variant="contained" color="success">
                      {t('search')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Toolbar>
      <DataGrid
        rows={reportStore.customerDailyReportRowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 50,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              {field: 'date', headerName: t('date'), flex:1},
              {field: 'name', headerName: t('name'), flex:1},
              {field: 'phone', headerName: t('phone'), flex:1},
              {field: 'address', headerName: t('address'), flex:1},
              {field: 'salesAmount', headerName: t('salesAmount'), flex:1, align:'right'},
              {field: 'cashPayment', headerName: t('cashPayment'), flex:1, align:'right'},
              {field: 'duePayment', headerName: t('duePayment'), flex:1, align:'right'},
              {field: 'advancePayment', headerName: t('advancePayment'), flex:1, align:'right'},
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
      />
    </Box>
    </>
  );
}

export default observer(DailyCustomerReprt);
