import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store/rootStore";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ListItemButton } from "@mui/material";
import { Button } from '@mui/material';
import UpdateItemForm from "./updateItemForm";
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';


const AllItemList:React.FC<any> = ({editMode}) => {

  const [edit, setEdit] = React.useState(0);
  const [editIndex, setEditIndex] = React.useState(null);
  const [discount, setDiscount] = React.useState<any>(0);
  const [payment, setPayment] = React.useState<any>(0);
  const [cartTotal, setCartTotal] = React.useState<any>(0);
  const [cartSubTotal, setSubCartTotal] = React.useState<any>(0);
  const [t, i18n] = useTranslation("global");

  const {
    rootStore: { purchaseStore },
  } = useStore();
  const { cartItems, removeFromCart } = purchaseStore;

  function ccyFormat(num: number) {
    const nFormat = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2});
    return nFormat.format(num);
    // return `${num.toFixed(2)}`;
  }

  function discountNumberFormate(e: any){
    let input = e.target.value.replace(/,/g, ''); // Remove commas for parsing
    let numberOnly = e.target.value.replace(/,/g, '');
    let subTotal = 0;
    if (!isNaN(input) && input !== '') {
      input = parseFloat(input).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      purchaseStore.cartDiscount = numberOnly;
      
      subTotal = (parseFloat(purchaseStore.cartTotal)+parseFloat(purchaseStore.cartExpense)) - numberOnly;
      // console.log('Calculation : '+numberOnly);
    } else if (input === '') {
      purchaseStore.cartDiscount = '';
      purchaseStore.setcartAditionalInputValues(0,payment);
      subTotal = parseFloat(purchaseStore.cartTotal)+parseFloat(purchaseStore.cartExpense);
    }
    // console.log(subTotal);
    purchaseStore.cartSubTotal = subTotal;
    let paidAmount = (payment !== '') ? payment:0;
    // console.log(paidAmount);
    let cartDue = purchaseStore.cartSubTotal - paidAmount;

    if(purchaseStore.supplierAdvance > 0){
      let calDue = cartDue - purchaseStore.supplierAdvance;
      if(calDue > 0){
        purchaseStore.calculateDue = calDue;
      }else{
        purchaseStore.calculateAdvance = Math.abs(calDue);
      }
    }else{
      let calDue = cartDue + purchaseStore.supplierDue;
      purchaseStore.calculateDue = calDue;
    }
  }

  function expenseNumberFormate(e: any){
    let input = e.target.value.replace(/,/g, ''); // Remove commas for parsing
    let numberOnly = e.target.value.replace(/,/g, '');
    let subTotal = 0;
    if (!isNaN(input) && input !== '') {
      input = parseFloat(input).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      purchaseStore.cartExpense = numberOnly;
      subTotal = (parseFloat(purchaseStore.cartTotal)+parseFloat(numberOnly)) - purchaseStore.cartDiscount;
      // console.log('Calculation : '+numberOnly);
    } else if (input === '') {
      purchaseStore.cartExpense = '';
      subTotal = purchaseStore.cartTotal - purchaseStore.cartDiscount;
    }
    // console.log(subTotal);
    purchaseStore.cartSubTotal = subTotal;
    let paidAmount = (payment !== '') ? payment:0;
    // console.log(paidAmount);
    let cartDue = purchaseStore.cartSubTotal - paidAmount;

    if(purchaseStore.supplierAdvance > 0){
      let calDue = cartDue - purchaseStore.supplierAdvance;
      if(calDue > 0){
        purchaseStore.calculateDue = calDue;
      }else{
        purchaseStore.calculateAdvance = Math.abs(calDue);
      }
    }else{
      let calDue = cartDue + purchaseStore.supplierDue;
      purchaseStore.calculateDue = calDue;
    }
  }

  function paymentNumberFormate(e: any){
    let input = e.target.value.replace(/,/g, ''); // Remove commas for parsing
    let numberOnly = e.target.value.replace(/,/g, '');
    let dueAmount = 0;
    if (!isNaN(input) && input !== '') {
      input = parseFloat(input).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      setPayment(input);
      purchaseStore.cartPayment = numberOnly;
      dueAmount = purchaseStore.cartSubTotal - numberOnly;
    } else if (input === '') {
      purchaseStore.cartPayment = '';
      purchaseStore.setcartAditionalInputValues(discount,0);
      dueAmount = purchaseStore.cartSubTotal;
    }

    let cartDue = dueAmount;

    if(purchaseStore.supplierAdvance > 0){
      let calDue = cartDue - purchaseStore.supplierAdvance;
      if(calDue > 0){
        purchaseStore.calculateDue = calDue;
      }else{
        purchaseStore.calculateAdvance = Math.abs(calDue);
      }
    }else{
      let calDue = cartDue + purchaseStore.supplierDue;
      purchaseStore.calculateDue = calDue;
    }
  }

  function subtotal(items: readonly any[]) {
    return items.map(({ total }) => total).reduce((sum, i) => sum + i, 0);
  }

  
  function handleEdit(index:any){
    console.log('handleEdit');
    setEdit(1);
    setEditIndex(index);
  }
  
  function removeEdit(index:any){
    console.log('removeEdit');
    setEdit(0);
    setEditIndex(null);
  }

  const formatNumberWithCommas = (value:any) => {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  function calculateDue(){
    
    if(purchaseStore.supplierAdvance > 0){
      let calculateDue = purchaseStore.cartDue - purchaseStore.supplierAdvance;
      if(calculateDue > 0){
        return calculateDue;
      }
    }
  }

  function calculateAdvance(){
    
    if(purchaseStore.supplierAdvance > 0){
      let calculateAdvance = purchaseStore.cartDue - purchaseStore.supplierAdvance;
      if(calculateAdvance < 0){
        return calculateAdvance;
      }
    }
  }

  const invoiceSubtotal = subtotal(cartItems);
  const invoiceTotal = invoiceSubtotal;
  // setCartTotal(invoiceTotal);
  // setSubCartTotal(invoiceSubtotal);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={5}>
              {t('details')}
            </TableCell>
            <TableCell align="right">{t('price')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{padding:'8px 15px' }}>#</TableCell>
            <TableCell sx={{padding:'8px 15px' }}>{t('product')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('price')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('quantity')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('discount_per')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('total')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartItems.map((row, i) => {
            let itemCount = row.units.length;
            let newUnits:any = [];
            for(var inc=0; inc<itemCount; inc++){
              newUnits.push({id:row.units[inc].id, name:row.units[inc].name, calculate_value:row.units[inc].calculate_value, operator:row.units[inc].operator });
            }

            let quantityCount = row.product_units.length;
            let newQuantity:any = [];
            for(var inc=0; inc<quantityCount; inc++){
              newQuantity.push({id:row.product_units[inc].id, name:row.product_units[inc].name, calculate_value:row.product_units[inc].calculate_value, operator:row.product_units[inc].operator, quantity:row.product_units[inc].quantity });
            }
            return (
              <TableRow key={i}>
              {
                edit === 1 && editIndex === i ?
                <>
                  <TableCell sx={{padding:'8px 15px' }} colSpan={7}>
                    <UpdateItemForm data={row.product_data} index={i} productUnits={newUnits} productDetails={{'product_name':row.product.label,'quantity':row.quantity, 'discount':row.discount,'total':row.total,'selected_unit':row.selected_unit}} removeEdit={removeEdit}/>
                  </TableCell>
                </>
                :
                <> 
                <TableCell sx={{padding:'8px 15px' }}>
                  {/* {editMode && editMode == true ? <ListItemButton onClick={() => removeFromCart(i)}><DeleteIcon /></ListItemButton>: <ListItemButton>{i+1}</ListItemButton>}
                  {editMode && editMode == true ? <ListItemButton onClick={() => removeFromCart(i)}><EditIcon /></ListItemButton>: <ListItemButton>{i+1}</ListItemButton>} */}
                  {
                    editMode && editMode == true ?
                    <>
                    <Button style={{maxWidth:'40px', minWidth:'40px', padding: '0px'}} onClick={()=>removeFromCart(i)}><DeleteIcon/></Button>
                    <Button style={{maxWidth:'40px', minWidth:'40px', padding: '0px'}} onClick={()=>handleEdit(i)}><EditIcon/></Button>
                    </>:''
                  }
                </TableCell>
                <TableCell sx={{padding:'8px 15px' }}>
                {row.product.label}
                </TableCell>
                <TableCell sx={{padding:'8px 15px' }} align="right">Tk. {ccyFormat(row.price.label)} {row.defaultUnitName}</TableCell>
                <TableCell sx={{padding:'8px 15px' }} align="right">
                  {
                    row.product_units.map((item:any, index:any)=>{
                      if(item.quantity != ''){
                        return (
                          <>
                          {item.quantity} {item.name}
                          {index < row.product_units.length - 1 && ', '}
                          </>
                          )
                      }
                    })
                  }
                </TableCell>
                <TableCell sx={{padding:'8px 15px' }} align="right">{row.discount}</TableCell>
                <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(row.total)}</TableCell>
                </>
              }
              
            </TableRow>
            )
          })}
          <TableRow>
            <TableCell rowSpan={7} />
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('subTotal')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(purchaseStore.cartTotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell align="right" sx={{padding:'8px 15px' }}>{t('discount')}</TableCell>
            <TableCell align="right" sx={{padding:'8px 15px' }}>
            <FormControl sx={{ m: 0, width: '150px', height: '30px'}} variant="filled">
              <FilledInput
                id="filled-adornment-weight"
                sx={{
                  padding: '0 5px',
                  textAlign: 'right',
                  '.MuiFilledInput-input': {
                    padding: '0 5px',
                    textAlign: 'right',
                  },
                  height:30
                }}
                inputProps={{
                  autoComplete: 'off',
                }}
                value={formatNumberWithCommas(purchaseStore.cartDiscount)}
                onChange={discountNumberFormate}
                
              />
            </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell align="right" sx={{padding:'8px 15px' }}>{t('expense')}</TableCell>
            <TableCell align="right" sx={{padding:'8px 15px' }}>
            <FormControl sx={{ m: 0, width: '150px', height: '30px'}} variant="filled">
              <FilledInput
                id="filled-adornment-weight"
                sx={{
                  padding: '0 5px',
                  textAlign: 'right',
                  '.MuiFilledInput-input': {
                    padding: '0 5px',
                    textAlign: 'right',
                  },
                  height:30
                }}
                inputProps={{
                  autoComplete: 'off',
                }}
                value={formatNumberWithCommas(purchaseStore.cartExpense)}
                onChange={expenseNumberFormate}
                
              />
            </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: 'none' }} />
            <TableCell sx={{ border: 'none' }} />
            <TableCell sx={{ border: 'none' }} />
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('netTotal')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(purchaseStore.cartSubTotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell align="right" sx={{padding:'8px 15px' }}>{t('payment')}</TableCell>
            <TableCell align="right" sx={{padding:'8px 15px' }}>
            <FormControl sx={{ m: 0, width: '150px', height: '30px'}} variant="filled">
              <FilledInput
                id="filled-adornment-weight"
                sx={{
                  padding: '0 5px',
                  textAlign: 'right',
                  '.MuiFilledInput-input': {
                    padding: '0 5px',
                    textAlign: 'right',
                  },
                  height:30
                }}
                inputProps={{
                  autoComplete: 'off',
                }}
                value={formatNumberWithCommas(purchaseStore.cartPayment)}
                onChange={paymentNumberFormate}
              />
            </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('due')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(purchaseStore.calculateDue)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{ border: 'none' }}/>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('totalAdvance')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(purchaseStore.calculateAdvance)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default observer(AllItemList);
