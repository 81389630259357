import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, TextField, Toolbar, useMediaQuery, useTheme, } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SupplierDetails from '../../../components/details/SupplierDetails';

const SupplierList = () => {

    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");
    const [supplierId, setSupplierId] = useState(0);
    
    const [dialogOpen, setDialogOpen] = useState(false);
    
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Full screen on small devices
    const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md")); // Medium devices

    const {rootStore: {supplierStore}} = useStore();

    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await supplierStore.fetchList();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const confirmDelete = async(id:any)=>{
      setLoading(true);
      try {
        const resData = await supplierStore.deleteData(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    const deleteData = (params:any) => {

      supplierStore.rootStore.dialogStore.openDialog({
        confirmFn: () => confirmDelete(params.id),
        dialogText: t("suppliers.deleteConfirmText")
      })
      // supplierStore.deleteDialog(params);
      // setLoading(false);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      supplierStore.rowData = supplierStore.searchData.filter((row)=>{
        return row.name.toLowerCase().includes(value)
         || row.company.toLowerCase().includes(value)
         || row.phone_number.toLowerCase().includes(value)
         || row.address.toLowerCase().includes(value);
      })
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const view = (params:any) => {

      setSupplierId(params.id);
      handleOpenDialog();

    }

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Dialog
    open={dialogOpen}
    onClose={handleDialogClose}
    fullScreen={fullScreen} // Fullscreen on small screens
    PaperProps={{
      style: {
        width: isMedium ? "90vw" : "85vw", // 90% width on medium screens, 80% on larger screens
        maxWidth: "95vw", // Maximum width constraint
        height: isMedium ? "95vh" : "90vh", // 80% height on medium, 60% on larger
        maxHeight: "95vh", // Maximum height constraint
      },
    }}
    >
        <DialogTitle>{t('suppliers.view')}</DialogTitle>
        <DialogContent
        sx={{
            minHeight: "70vh", // Minimum height for the content area
            padding: "16px", // Optional: Customize padding
        }}
        >
          <SupplierDetails id={supplierId}/>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleDialogClose}>
            {t('cancel')}
        </Button>
        </DialogActions>
    </Dialog>
    <Box sx={{ height: 400, width: '100%' }}>
       <Button variant="contained" sx={{mb:2}} component={Link} to="create"><PersonAddIcon sx={{mr:1}}/>{t('addNew')}</Button>
       <Toolbar style={{padding:0}}>
        <TextField
          variant="outlined"
          label={t('search')}
          value={searchText}
          onChange={handleSearch}
          sx={{
            margin: 0, // Outer margin
            '& .MuiInputBase-root': {
              height: '40px', // Input height
              padding: '0 0px', // Input padding
              fontSize: '14px'
            },
            '& .MuiInputLabel-root': {
              padding: '0',
              marginTop: '-5px',
              fontSize: '14px',
            },
          }}
        />
      </Toolbar>
      <DataGrid
        rows={supplierStore.rowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 50,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              {field: 'name', headerName: t('name'), width: 200},
              {field: 'company', headerName: t('company'), width: 220},
              {field: 'phone_number', headerName: t('phone'), width: 250},
              {field: 'address', headerName: t('address'), width: 200},
              {
                  field: 'actions',
                  headerName: t('action'),
                  width: 150,
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => (
                      <>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>view(params)}><VisibilityIcon/></Button>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'edit/'+params.id}><EditIcon/></Button>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>deleteData(params)}><DeleteIcon/></Button>
                      </>
                  )
              }
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
    </>
  );
}

export default observer(SupplierList);
