import React from "react";
import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./rootStore";
import { GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { Button, ListItem, ListItemButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Define your row type
interface PurchasesRowModel extends GridValidRowModel {
  invoice_number: string;
  supplier_name: string;
  company: string;
  formatted_price: string;
  formatted_discount: string;
  netAmount: number;
  formatted_date: string;
  formatted_time: string;
}

export default class PurchaseStore {
    
    BASE_URL = process.env.REACT_APP_API_URL + '/v1/purchases';
    
    cartItems: any[] = []
    cartAditionalInputValues: any = {discount:0,payment:0}
    supplierDue: any = 0
    supplierAdvance: any = 0
    calculateDue: any = 0
    calculateAdvance: any = 0
    cartTotal: any = 0
    cartDiscount: any = 0
    cartExpense: any = 0
    cartPayment: any = 0
    cartSubTotal: any = 0
    cartDue: any = 0
    rootStore: IRootStore;
    rowData: PurchasesRowModel[] = [];
    searchData: PurchasesRowModel[] = [];
    columns: GridColDef[] = [
        // {field: 'id', headerName: 'Id', width: 100},
        
    ];

    constructor(rootStore: IRootStore) {
      makeObservable(this, {
          cartItems: observable,
          supplierDue: observable,
          supplierAdvance: observable,
          calculateDue: observable,
          calculateAdvance: observable,
          cartTotal: observable,
          cartDiscount: observable,
          cartExpense: observable,
          cartPayment: observable,
          cartSubTotal: observable,
          cartDue: observable,
          cartAditionalInputValues: observable,
          rowData: observable,
          columns: observable,
          setRowData: action,
          fetchList: action,
          createData: action,
          deleteData: action,
          setCartItems: action,
          setcartAditionalInputValues: action,
          addToCart: action,
          removeFromCart: action,
      });
      this.rootStore = rootStore;
  }

  setRowData(values: PurchasesRowModel[]) {
      this.rowData = values;
      this.searchData = values;
  }

  setCartItems = (items: any[]) => {
      this.cartItems = items;
  }
  
  setcartAditionalInputValues = (discount:any,payment:any) => {
        this.cartAditionalInputValues.discount = discount;
        this.cartAditionalInputValues.payment = payment;
    }

  addToCart= async (value: any): Promise<boolean> => {
      console.clear();
      console.log(value);
      if(value.itemIndex === -1){
        
        const existsVariant = this.cartItems.some((item)=>item.product.id==value.product.id);
        const existsPrice = this.cartItems.some((item)=>item.price.label==value.price.label);
        if(existsVariant && existsPrice){
          // console.log('Update item of cart');
          const targetIndex = this.cartItems.findIndex(item => item.product.id==value.product.id && item.price.label==value.price.label);

          // console.log('Index-'+targetIndex);
          
          const pre_product_units = this.cartItems[targetIndex].product_units;
          const cur_product_units = value.product_units;

          const mergeProductUnits = (arr1:any, arr2:any) => {
            const combined = [...arr1, ...arr2];
        
            // Reduce to a single array where quantities for matching item_id are summed
            const result = combined.reduce((acc, current) => {
              const existingItem = acc.find((item:any) => item.unit_id === current.unit_id);
              if (existingItem) {
                
                // if(current.operator=='/'){
                //   console.log('divide');
                //   const totalQty = Number(existingItem.quantity) + Number(current.quantity);
                //   if(Number(totalQty) >= Number(current.calculate_value)){
                //     console.log(totalQty);
                //     console.log('over');
                //     const existingItem1 = acc.find((item:any) => item.operator=='*');
                //     existingItem1.quantity = Number(existingItem1.quantity) + (Number(totalQty)/Number(current.calculate_value));
                //   }
                // }else{
                  existingItem.quantity = Number(existingItem.quantity) + Number(current.quantity);
                // }
                
              } else {
                acc.push({ ...current });
              }
              return acc;
            }, []);
        
            return result;
          };
          // console.log(pre_product_units);
          // console.log(cur_product_units);
          const mergedArray = mergeProductUnits(pre_product_units, cur_product_units);
          // console.log(mergedArray);
          this.cartItems[targetIndex].product_units = mergedArray;
          this.cartItems = this.cartItems.map((item, i) => (item.product.id==value.product.id && item.price.label==value.price.label ? { ...item, total:item.total+value.total } : item));
        }else{
          console.log('Add item to cart');
          this.cartItems.push(value);
        }
        
      }else{
        // console.log(value.itemIndex);
        this.cartItems = this.cartItems.map((item, i) => (i===value.itemIndex ? { ...item, ...value } : item));
        // console.log('Update item of cart');
      }
      this.cartTotal = this.subTotal(this.cartItems);
      this.cartSubTotal = this.subTotal(this.cartItems);
      this.cartDue = this.subTotal(this.cartItems);
      
      if(this.supplierAdvance > 0){
        let calDue = this.subTotal(this.cartItems) - this.supplierAdvance;
        if(calDue > 0){
          this.calculateDue = calDue;
        }else{
          this.calculateAdvance = Math.abs(calDue);
        }
      }else{
        let calDue = this.subTotal(this.cartItems) + this.supplierDue;
        this.calculateDue = calDue;
      }

      return Promise.resolve(true);
  }
  removeFromCart = async (index: any) =>  {
      this.cartItems.splice(index, 1);
      this.cartTotal = this.subTotal(this.cartItems);
      this.cartSubTotal = this.subTotal(this.cartItems);;
      this.cartDue= this.subTotal(this.cartItems);
      if(this.supplierAdvance > 0){
        let calDue = this.subTotal(this.cartItems) - this.supplierAdvance;
        if(calDue > 0){
          this.calculateDue = calDue;
        }else{
          this.calculateAdvance = Math.abs(calDue);
        }
      }else{
        let calDue = this.subTotal(this.cartItems) + this.supplierDue;
        this.calculateDue = calDue;
      }
  }

  subTotal = (items: readonly any[]) =>{
    return items.map(({ total }) => total).reduce((sum, i) => sum + i, 0);
  }

  //   Api Calls
  fetchList = async (fromDate: string, toDate: string) => {
      try {
          const response = await fetch(this.BASE_URL + `/list/${fromDate}/${toDate}`, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              }
          })
          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              this.setRowData(data.data.purchases);
              return Promise.resolve(data)
          }
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  calculateFinalPrice = (original: number, discount: number, quantity: number): number => {
      const finalPrice = original - (original *  discount / 100);
      return finalPrice*quantity
  }

  //create new price
  createPriceData = async (postData: any) => {
      try {
          const response = await fetch(this.BASE_URL + `/createPriceData`, {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              },
              body: JSON.stringify(postData)
          })

          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, data.message, data);
              return Promise.reject(data)
          } else {
              this.rootStore.alertStore.open({status: "success", message: data.message})
              return Promise.resolve(data)
          }
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  // View
  getData = async (id: number | string) => {
    try {
      
      const response = await fetch(this.BASE_URL + `/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })
      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        // console.log(data);
        return Promise.resolve(data.data.purchase)
      }
    
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Create
  createData = async (supplierData: any) => {
      try {
          const postDataProducts =[...this.cartItems].map((e: any)=>{
              const productUnit = [...e.product_units].map((unit:any)=>{
                return {
                  unit:unit.id,
                  quantity:unit.quantity
                }
              })
              return {
                  variant_id: e.product.id,
                  price_id: e.price.id,
                  price_label: e.price.label,
                  discount: e.discount,
                  product_units: JSON.stringify(productUnit),
                  withOutDiscount:e.withOutDiscount,
                  total:e.total
              }
          })

        //   console.log("createData", postDataProducts)
          
          const formData = new FormData();
          for (const property in this.cartAditionalInputValues) {
            formData.append(property, this.cartAditionalInputValues[property]);
          }
          formData.append("discount", this.cartDiscount);
          formData.append("expense", this.cartExpense);
          formData.append("payment", this.cartPayment);
          formData.append("supplier_id", supplierData.supplier?.id);
          formData.append("user_id", this.rootStore.authStore.userId ?? "");
          postDataProducts.forEach((item:any, i: number) => {
              Object.keys(item).map((key:any) => {
                  formData.append(`products[${i}][${key}]`, item[key]);
              });
          })
          const response = await fetch(this.BASE_URL, {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
              },
              body: formData
          })

          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, data.message, data);
              return Promise.reject(data)
          } else {
              this.rootStore.alertStore.open({status: "success", message: data.message})
              return Promise.resolve(data)
          }
        
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  // Delete
  deleteData = async (id: number | string) => {
    try {
      const response = await fetch(this.BASE_URL + `/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setRowData(this.rowData.filter((e:any) => e.id != id))
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }
}