import React from "react";
import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./rootStore";
import { GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { Button, ListItem, ListItemButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Define your row type
interface SupplierRowModel extends GridValidRowModel {
  name: string;
  company: string;
  phone_number: string;
  address: string;
}

interface PaymentRowModel extends GridValidRowModel {
  name: string;
  company: string;
  phone_number: string;
  address: string;
  type: string;
  amount: string;
  date: string;
  time: string;
}

export default class SupplierStore {
    
    BASE_URL = process.env.REACT_APP_API_URL + '/v1/suppliers';
    
    rootStore: IRootStore;
    rowData: SupplierRowModel[] = [];
    searchData: SupplierRowModel[] = [];
    supplierPaymentRowData: PaymentRowModel[] = [];
    detailsSupplierPaymentRowData: PaymentRowModel[] = [];
    supplierSearchPaymentData: PaymentRowModel[] = [];
    supplierDetailsSearchPaymentData: PaymentRowModel[] = [];
    columns: GridColDef[] = [
        // {field: 'id', headerName: 'Id', width: 100},
        
    ];

    constructor(rootStore: IRootStore) {
        makeObservable(this, {
            rowData: observable,
            searchData: observable,
            supplierPaymentRowData: observable,
            supplierSearchPaymentData: observable,
            detailsSupplierPaymentRowData: observable,
            supplierDetailsSearchPaymentData: observable,
            columns: observable,
            setRowData: action,
            setPaymentRowData: action,
            setDetailsPaymentRowData: action,
            fetchList: action,
            createData: action,
            getData: action,
            updateData: action,
            getList: action,
            fetchPaymentList: action,
            createPaymentData: action,

        });
        this.rootStore = rootStore;
    }

    setRowData(values: SupplierRowModel[]){
        this.rowData = values;
        this.searchData = values;
    }

    setPaymentRowData(values: PaymentRowModel[]){
        this.supplierPaymentRowData = values;
        this.supplierSearchPaymentData = values;
    }

    setDetailsPaymentRowData(values: PaymentRowModel[]){
        this.detailsSupplierPaymentRowData = values;
        this.supplierDetailsSearchPaymentData = values;
    }

    // Show
    fetchList = async () => {
        try {
            
            const response = await fetch(this.BASE_URL + '/list', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json'
                }
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
                return Promise.reject(data)
            } else {
                this.setRowData(data.data.suppliers);
                return Promise.resolve(data)
            }

        } catch (error) {
            this.rootStore.handleError(419, "Something went wrong!", error);
        }
    }

    // Create
    createData = async (postData: any) => {
        try {
            const response = await fetch(this.BASE_URL, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json', // You can adjust this header as needed
                },
                body: JSON.stringify(postData)
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, data.message, data);
                return Promise.reject(data)
            } else {
                this.rootStore.alertStore.open({status: "success", message: data.message})
                return Promise.resolve(data)
            }
        } catch (error: any) {
            this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }

    // View
    getData = async (id: number | string) => {
      try {

        const response = await fetch(this.BASE_URL + `/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.rootStore.authStore.token}`,
            'Content-Type': 'application/json', // You can adjust this header as needed
          }
        })
        const data = await response.json();
        if (data.error) {
          this.rootStore.handleError(response.status, data.message, data);
          return Promise.reject(data)
        } else {
          return Promise.resolve(data)
        }
      } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
      }
    }

    // View
    getSupplierDetails = async (id: number | string) => {
      try {

        const response = await fetch(this.BASE_URL + `/spplierDetails/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.rootStore.authStore.token}`,
            'Content-Type': 'application/json', // You can adjust this header as needed
          }
        })
        const data = await response.json();
        if (data.error) {
          this.rootStore.handleError(response.status, data.message, data);
          return Promise.reject(data)
        } else {
          this.setDetailsPaymentRowData(data.data.payments);
          return Promise.resolve(data)
        }
      } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
      }
    }

  // Update
  updateData = async (id: number | string, postData: any) => {
    try {
      const response = await fetch(this.BASE_URL + `/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        },
        body: JSON.stringify(postData)
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status,  data.message, data);
        return Promise.reject(data)
      } else {
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Delete
  deleteData = async (id: number | string) => {
    try {
      const response = await fetch(this.BASE_URL + `/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setRowData(this.rowData.filter((e:any) => e.id != id))
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  //   Api Calls
  getList = async (postData:any) => {
    try {
          const response = await fetch(this.BASE_URL + '/getList', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              },
              body: JSON.stringify(postData)
          })
          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              return Promise.resolve(data.data.suppliers)
          }
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  // Show
  fetchPaymentList = async (fromDate: string, toDate: string) => {
      try {
          
          const response = await fetch(this.BASE_URL + `/paymentList/${fromDate}/${toDate}`, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json'
              }
          })

          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              console.log(data.data.payments);
              this.setPaymentRowData(data.data.payments);
              return Promise.resolve(data)
          }

      } catch (error) {
          this.rootStore.handleError(419, "Something went wrong!", error);
      }
  }

  // Create
  createPaymentData = async (postData: any) => {
        try {
            const formData = new FormData();
            formData.append("supplier_id", postData.supplier?.id);
            formData.append("account_id", postData.account_id);
            formData.append("total_due", postData.total_due);
            formData.append("new_payment", postData.new_payment);
            formData.append("user_id", this.rootStore.authStore.userId ?? "");
            
            const response = await fetch(this.BASE_URL + '/paymentCreate', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                },
                body: formData
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, data.message, data);
                return Promise.reject(data)
            } else {
                // console.log(data);
                this.rootStore.alertStore.open({status: "success", message: data.message})
                return Promise.resolve(data)
            }
                
        } catch (error: any) {
            this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }

  // Delete
//   deleteDialog = async (params: any) => {
//     this.rootStore.dialogStore.openDialog({
//         confirmFn: () => this.deleteData(params.id),
//         dialogText: "Are you sure you want to delete this item ?"
//     })
//   }

}