import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, Button, CardMedia, Card, MenuItem, IconButton, Typography } from '@mui/material';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from "@mui/icons-material/CloudUpload";


const ProductCreate = () => {

    const [imageUrl, setImageUrl] = useState<string|null>("../../default_image.png");
    const [categories, setCategories] = useState<any[]>([]);
    const [units, setUnits] = useState<any[]>([]);
    const { rootStore: { productStore } } = useStore();
    const { createData, initCategories, initUnits } = productStore;
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [defaultUnits, setDefaultUnits] = useState<any[]>([]);
    const [hover, setHover] = useState(false);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Product name is required'),
        category_id: Yup.string(),
        units: Yup.array().min(1, 'At least one option must be selected')
        .required('At least one option must be selected'),
        default_unit: Yup.string().required('You must select an option'),
        image: Yup.mixed().test('fileType', 'Unsupported file format', (value:any) => {
            if (value !== ""){
            const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
            return supportedFormats.includes(value.type);
            }
            return true; // skip this
        }).test('fileSize', 'File size is too large (max: 5000KB)', (value:any) => {
            if (value !== "") {
            return value.size <= 5000000; // 5000KB in bytes
            }
            return true; // skip this
        }),
        alert_limit: Yup.number().required('Alert limit is required').min(0, 'Minimum alert limit is 0'),
        variant: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("Variant name is required"),
              prices: Yup.array().of(
                Yup.object().shape({
                    purchase_price: Yup.string().required("Purchase price required"),
                    sale_price: Yup.string().required("Sale price is required"),
                    stock: Yup.string(),
                })
              ),
              
            })
        ),
    })

    type FormValues = Yup.InferType<typeof validationSchema>;

    const { control, register, handleSubmit, setValue, formState: { errors }, reset, setError } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: "",
            category_id: "",
            units:[],
            default_unit: "",
            image: "",
            alert_limit: 0,
            variant: [{ name: "", prices:[{purchase_price: "", sale_price: "", stock: ""}] }],
        }
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "variant", // Name of the field array
    });

    const onSubmit = async (data:any) => {
        
        data.variant.prices = JSON.stringify(data.variant.prices);
        data.variant = JSON.stringify(data.variant);

        // console.log(data);
        
        setLoading(true);
        try {
            const formData = new FormData();
            Object.keys(data).map(key => {
              formData.append(key, data[key]);
            })
            const resData = await createData(formData)
            // console.log(resData);
            
            if (resData){
              reset()
              navigate('..')
            }
            
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
                setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
                });
            })
            setLoading(false);
        }
        
    }

    const handleKeyDown = (e:any) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent form submission
        }
    };

    const initCategoriesData = async () => {
        try{
            setLoading(true);
            const resData = await initCategories();
            if(resData){
                // console.log(resData);
                setLoading(false);
                setCategories(resData.data.categories)
            }
            
        }catch(e: any){
            setLoading(false);
            console.log(e);
        }
    }

    const initUnitsData = async () => {
        try{
            setLoading(true);
            const resData = await initUnits();
            if(resData){
                setLoading(false);
                // console.log(resData);
                setUnits(resData.data.units)
            }
            
        }catch(e: any){
            setLoading(false);
            console.log(e);
        }
    }

    const handleImageClick = () => {
        document.getElementById("image")?.click();
    }

    useEffect(() => {
        initCategoriesData()
        initUnitsData()
    }, [])

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        
        <Box sx={{ width: '100%' }}>
            <h4>{t('products.create')}</h4>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown} autoComplete="off">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={5}>
                <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="name"
                    label={t("name")}
                    variant="filled"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          paddingleft: '8px', // Input padding
                          paddingTop:'0px',
                          fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
            </Grid>
            {/* <Grid item xs={6}>
                <Controller
                name="category_id"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    select
                    id="category_id"
                    label={t("category")}
                    variant="filled"
                    error={!!errors.category_id}
                    helperText={errors.category_id?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          paddingleft: '8px', // Input padding
                          paddingTop:'0px',
                          fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    >
                    {categories.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                        {option.name}
                        </MenuItem>
                    ))}
                    </TextField>
                )}
                />
            </Grid> */}
            <Grid item xs={3}>
                <label htmlFor="">{t('unit')}</label><br/>
                <Controller
                name="units"
                control={control}
                render={({ field }) => (
                    <>
                    {
                        units.map((unit)=>(
                            <label>
                                <input
                                type="checkbox"
                                value={unit.id}
                                onChange={(e) => {
                                    setValue('default_unit','');
                                    const value = e.target.value;
                                    field.onChange(
                                    e.target.checked
                                        ? [...field.value, value]
                                        : field.value.filter((val) => val !== value)
                                    );
                                    var elements = document.getElementsByTagName("input");

                                    
                                    if(e.target.checked){
                                        var defaultObject = {id:value,text:unit.name};
                                        setDefaultUnits([...defaultUnits, defaultObject]);
                                    }else{
                                        setDefaultUnits(items=>items.filter(item=>item.id!==value));
                                    }
                                }}
                                checked={field.value.includes(unit.id.toString())}
                                />
                                {unit.name}
                            </label>
                        ))
                    
                    }
                    </>
                )}
                />
                {errors.units && <p style={{color:'#d32f2f', fontFamily: "sans-serif", fontWeight:'400', fontSize: '0.75rem', marginTop:'4px', textAlign:'left', marginLeft:'14px', marginRight:'14px', letterSpacing:'0.03333em'}}>{errors.units.message}</p>}
            </Grid>
            <Grid item xs={2}>
                <label htmlFor="">{t('defaultUnit')}</label><br/>
                {defaultUnits.map((defaultUnit)=>(
                    <>
                    <label>
                    <input
                        type="radio"
                        value={defaultUnit.id}
                        {...register('default_unit')}
                    />
                    {defaultUnit.text}
                    </label>
                    </>
                ))}
                {errors.default_unit && <p style={{color:'#d32f2f', fontFamily: "sans-serif", fontWeight:'400', fontSize: '0.75rem', marginTop:'4px', textAlign:'left', marginLeft:'14px', marginRight:'14px', letterSpacing:'0.03333em'}}>{errors.default_unit.message}</p>}
            </Grid>
            <Grid item xs={2}>
                <Controller
                name="alert_limit"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="alert_limit"
                    label={t("alertLimit")}
                    variant="filled"
                    error={!!errors.alert_limit}
                    helperText={errors.alert_limit?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={12}>
                <h4 style={{padding:0,margin:0}}>{t("variantName")}</h4>
            </Grid>
            <Grid item xs={12}>
            {fields.map((item, index) => (
            <Box
                key={item.id}
                sx={{
                display: "flex",
                gap: 2,
                marginBottom: 2,
                alignItems: "flex-start",
                }}
            >
                <Controller
                name={`variant.${index}.name`}
                control={control}
                render={({ field, fieldState }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="variant_name"
                    label={t("variantName")}
                    variant="filled"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
                <Box
                component="fieldset"
                sx={{
                    width: '100%', // Total width including border
                    height: 'auto',
                    border: "1px solid grey", // Border size
                    boxSizing: "border-box", // Include border in the width
                    textAlign: "left",
                    padding: '10px',
                    borderRadius: '5px',
                    position:'relative'
                  }}
                >
                <Typography component="legend" sx={{ fontSize: ".8rem", color: "#000", position:'absolute', top:'-12px', backgroundColor:'#fff' }}>
                    {t('priceDetails')}
                </Typography>
                <PriceFiels
                control={control}
                register={register}
                index={index}
                errors={errors}
                />
                </Box>
                <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => remove(index)}
                >
                <DeleteIcon/>
                </Button>
            </Box>
            ))}

            <Button
            variant="contained"
            color="primary"
            onClick={() => append({ name: "", prices:[{purchase_price: "", sale_price: "", stock: ""}] })}
            sx={{ marginBottom: 2 }}
            >
            <AddIcon/>
            </Button>
            </Grid>
            <Grid item xs={6}>
                {imageUrl && <Card
                sx={{
                    position: "relative",
                    width: 300,
                    height: 200,
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                {/* CardMedia for background image */}
                <CardMedia
                component="img"
                alt="green iguana"
                height="auto"
                image={imageUrl ?? ""}
                sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    transition: "opacity 0.3s ease-in-out",
                    opacity: hover ? 0.7 : 1,
                }}
                />
                {/* Overlay with upload icon */}
                <Box
                    sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: hover ? "rgba(0, 0, 0, 0.5)" : "transparent",
                    transition: "background-color 0.3s ease-in-out",
                    }}
                >
                    {hover && (
                    <IconButton
                        sx={{
                        color: "white",
                        fontSize: "2rem",
                        }}
                        onClick={handleImageClick}
                    >
                        <UploadIcon fontSize="inherit" />
                    </IconButton>
                    )}
                </Box>
                </Card>}
                <Controller
                name="image"
                control={control}
                render={({ field }) => (
                    <TextField
                    fullWidth
                    id="image"
                    type='file'
                    label="Zip code"
                    variant="filled"
                    style={{display:'none'}}
                    inputProps={{ accept: "image/*" }}
                    focused
                    onChange={(e: any) => {
                        field.onChange(e.target.files[0])
                        e.target.files.length > 0 ? setImageUrl(URL.createObjectURL(e.target.files[0])) : setImageUrl("../../default_image.png")
                    }}
                    error={!!errors.image}
                    helperText={errors.image?.message}
                    />
                )}
                />
            </Grid>
                </Grid>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} type="submit" variant="contained" color="success">
                {t('save')}
                </Button>
            </form>
        </Box>
        </>
    )
}

const PriceFiels = (props:any) => {
    const [t, i18n] = useTranslation("global");

    const { fields:priceFileds, append, remove} = useFieldArray({
        control:props.control,
        name: `variant.${props.index}.prices`
    });

    return (
        <div>
            {priceFileds.map((priceField, priceIndex)=>(
                <Box key={priceField.id} display="flex" gap={2} mb={0.5}>
                    {/* {priceIndex} */}
                    <Controller
                    name={`variant.${props.index}.prices.${priceIndex}.purchase_price`}
                    control={props.control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="variant_name"
                        label={t("purchasePrice")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        />
                    )}
                    />
                    <Controller
                    name={`variant.${props.index}.prices.${priceIndex}.sale_price`}
                    control={props.control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="variant_name"
                        label={t("salePrice")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        />
                    )}
                    />
                    <Controller
                    name={`variant.${props.index}.prices.${priceIndex}.stock`}
                    control={props.control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="variant_name"
                        label={t("stock")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        />
                    )}
                    />
                    <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => remove(priceIndex)}
                    >
                    <DeleteIcon/>
                    </Button>
                </Box>
            ))}
            <Button
                variant="contained"
                onClick={() => append({ purchase_price: "", sale_price: "", stock: "" })}
            >
            <AddIcon/>
            </Button>
        </div>
    )
}

export default observer(ProductCreate);