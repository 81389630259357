import React from "react";
import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./rootStore";
import { GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { Button, ListItem, ListItemButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Define your row type
interface CustomerRowModel extends GridValidRowModel {
  name: string;
  phone_number: string;
  address: string;
}

interface PaymentRowModel extends GridValidRowModel {
  name: string;
  phone_number: string;
  address: string;
  type: string;
  amount: string;
  status: string;
  date: string;
  time: string;
}

interface PreRowModel extends GridValidRowModel {
  name: string;
  phone_number: string;
  address: string;
  amount: string;
}

export default class CustomerStore {
    
    BASE_URL = process.env.REACT_APP_API_URL + '/v1/customers';
    
    rootStore: IRootStore;
    rowData: CustomerRowModel[] = [];
    searchData: CustomerRowModel[] = [];
    paymentRowData: PaymentRowModel[] = [];
    detailsPaymentRowData: PaymentRowModel[] = [];
    searchPaymentData: PaymentRowModel[] = [];
    searchDetailsPaymentData: PaymentRowModel[] = [];
    preDueRowData: PreRowModel[] = [];
    searchPreDueData: PreRowModel[] = [];
    preAdvanceRowData: PreRowModel[] = [];
    searchPreAdvanceData: PreRowModel[] = [];
    columns: GridColDef[] = [
        // {field: 'id', headerName: 'Id', width: 100},
        
    ];

    constructor(rootStore: IRootStore) {
        makeObservable(this, {
            rowData: observable,
            searchData: observable,
            paymentRowData: observable,
            detailsPaymentRowData: observable,
            searchPaymentData: observable,
            searchDetailsPaymentData: observable,
            preDueRowData: observable,
            searchPreDueData: observable,
            preAdvanceRowData: observable,
            searchPreAdvanceData: observable,
            columns: observable,
            setRowData: action,
            setPaymentRowData: action,
            setDetailsPaymentRowData: action,
            fetchList: action,
            createData: action,
            getData: action,
            updateData: action,
            fetchPaymentList: action,
            createPaymentData: action,
            fetchPreDueList: action,
            setPreDueRowData: action,
            createPreDueData: action,
            setPreAdvanceRowData: action,

        });
        this.rootStore = rootStore;
    }

    setRowData(values: CustomerRowModel[]){
        this.rowData = values;
        this.searchData = values;
    }

    setPaymentRowData(values: PaymentRowModel[]){
        this.paymentRowData = values;
        this.searchPaymentData = values;
    }

    setDetailsPaymentRowData(values: PaymentRowModel[]){
        this.detailsPaymentRowData = values;
        this.searchDetailsPaymentData = values;
    }

    setPreDueRowData(values: PreRowModel[]){
        this.preDueRowData = values;
        this.searchPreDueData = values;
    }

    setPreAdvanceRowData(values: PreRowModel[]){
        this.preAdvanceRowData = values;
        this.searchPreAdvanceData = values;
    }

    // Show
    fetchList = async () => {
        try {
            
            const response = await fetch(this.BASE_URL + '/list', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json'
                }
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
                return Promise.reject(data)
            } else {
                this.setRowData(data.data.customers);
                return Promise.resolve(data)
            }

        } catch (error) {
            this.rootStore.handleError(419, "Something went wrong!", error);
        }
    }

    // Create
    createData = async (postData: any) => {
        try {
            const response = await fetch(this.BASE_URL, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json', // You can adjust this header as needed
                },
                body: JSON.stringify(postData)
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, data.message, data);
                return Promise.reject(data)
            } else {
                this.rootStore.alertStore.open({status: "success", message: data.message})
                return Promise.resolve(data)
            }
        } catch (error: any) {
            this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }

    // View
    getData = async (id: number | string) => {
      try {

        const response = await fetch(this.BASE_URL + `/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.rootStore.authStore.token}`,
            'Content-Type': 'application/json', // You can adjust this header as needed
          }
        })
        const data = await response.json();
        if (data.error) {
          this.rootStore.handleError(response.status, data.message, data);
          return Promise.reject(data)
        } else {
          return Promise.resolve(data)
        }
      } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
      }
    }

  // View
  getCustomerDetails = async (id: number | string) => {
    try {

      const response = await fetch(this.BASE_URL + `/customerDetails/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })
      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setDetailsPaymentRowData(data.data.payments);
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Update
  updateData = async (id: number | string, postData: any) => {
    try {
      const response = await fetch(this.BASE_URL + `/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        },
        body: JSON.stringify(postData)
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status,  data.message, data);
        return Promise.reject(data)
      } else {
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  updateValueByKey = (array:any, key:any, valueToMatch:any, updateKey:any, newValue:any) => {
    return array.map((item:any) =>
      item[key] === valueToMatch
        ? { ...item, [updateKey]: newValue }
        : item
    );
  };

  // Update
  updatePaymentStatus = async (id: number | string, tableId: number | string, type: string, status:string ) => {
    try {
      const response = await fetch(this.BASE_URL + `/updatePaymentStatus/${tableId}/${type}/${status}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        },
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status,  data.message, data);
        return Promise.reject(data)
      } else {
        this.rootStore.alertStore.open({status: "success", message: data.message})
        let status_ = '';
        if(status === 'Pending'){
          status_ = 'Approved';
        }else{
          status_ = 'Pending';
        }

        // Example usage: Update status of the object with id=2
        const updatedData = this.updateValueByKey(this.paymentRowData, "id", id, "status", status_);
        this.setPaymentRowData(updatedData);
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Delete
  deleteData = async (id: number | string) => {
    try {
      const response = await fetch(this.BASE_URL + `/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setRowData(this.rowData.filter((e:any) => e.id != id))
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Delete
  deletePreDueData = async (id: number | string) => {
    try {
      const response = await fetch(this.BASE_URL + `/deletePreDue/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setPreDueRowData(this.preDueRowData.filter((e:any) => e.id != id))
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Delete
  deletePreAdvanceData = async (id: number | string) => {
    try {
      const response = await fetch(this.BASE_URL + `/deletePreAdvance/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setPreAdvanceRowData(this.preAdvanceRowData.filter((e:any) => e.id != id))
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Delete
//   deleteDialog = async (params: any) => {
//     this.rootStore.dialogStore.openDialog({
//         confirmFn: () => this.deleteData(params.id),
//         dialogText: "Are you sure you want to delete this item ?"
//     })
//   }

  //   Api Calls
  getList = async (postData:any) => {
    try {
          const response = await fetch(this.BASE_URL + '/getList', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              },
              body: JSON.stringify(postData)
          })
          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              return Promise.resolve(data.data.customers)
          }
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  // Show
  fetchPaymentList = async (fromDate: string, toDate: string) => {
      try {
          const dateData = {fromDate, toDate};
          const response = await fetch(this.BASE_URL + `/paymentList/${fromDate}/${toDate}`, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json'
              },
          })

          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              // console.log(data.data.payments);
              this.setPaymentRowData(data.data.payments);
              return Promise.resolve(data)
          }

      } catch (error) {
          this.rootStore.handleError(419, "Something went wrong!", error);
      }
  }

  // Create
  createPaymentData = async (postData: any) => {
        try {
            const formData = new FormData();
            formData.append("customer_id", postData.customer?.id);
            formData.append("total_due", postData.total_due);
            formData.append("new_payment", postData.new_payment);
            formData.append("user_id", this.rootStore.authStore.userId ?? "");
            
            const response = await fetch(this.BASE_URL + '/paymentCreate', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                },
                body: formData
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, data.message, data);
                return Promise.reject(data)
            } else {
                // console.log(data);
                this.rootStore.alertStore.open({status: "success", message: data.message})
                return Promise.resolve(data)
            }
                
        } catch (error: any) {
            this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }

    // Show
    fetchPreDueList = async () => {
      try {
          
          const response = await fetch(this.BASE_URL + `/preDueList`, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json'
              },
          })

          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              // console.log(data.data.payments);
              this.setPreDueRowData(data.data.predues);
              return Promise.resolve(data)
          }

      } catch (error) {
          this.rootStore.handleError(419, "Something went wrong!", error);
      }
    }

    // Create
    createPreDueData = async (postData: any) => {
      try {
          const formData = new FormData();
          formData.append("customer_id", postData.customer?.id);
          formData.append("new_due", postData.new_due);
          formData.append("user_id", this.rootStore.authStore.userId ?? "");
          
          const response = await fetch(this.BASE_URL + '/preDueCreate', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
              },
              body: formData
          })

          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, data.message, data);
              return Promise.reject(data)
          } else {
              // console.log(data);
              this.rootStore.alertStore.open({status: "success", message: data.message})
              return Promise.resolve(data)
          }
              
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
    }

    // Show
    fetchPreAdvanceList = async () => {
      try {
          
          const response = await fetch(this.BASE_URL + `/preAdvanceList`, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json'
              },
          })

          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              // console.log(data.data.payments);
              this.setPreAdvanceRowData(data.data.preadvances);
              return Promise.resolve(data)
          }

      } catch (error) {
          this.rootStore.handleError(419, "Something went wrong!", error);
      }
    }

    // Create
    createPreAdvanceData = async (postData: any) => {
      try {
          const formData = new FormData();
          formData.append("customer_id", postData.customer?.id);
          formData.append("new_advance", postData.new_advance);
          formData.append("user_id", this.rootStore.authStore.userId ?? "");
          
          const response = await fetch(this.BASE_URL + '/preAdvanceCreate', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
              },
              body: formData
          })

          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, data.message, data);
              return Promise.reject(data)
          } else {
              // console.log(data);
              this.rootStore.alertStore.open({status: "success", message: data.message})
              return Promise.resolve(data)
          }
              
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
    }

}