import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Grid, TextField, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import DatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';

const CustomerPaymentList = () => {

    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");

    const {rootStore: {customerStore, authStore}} = useStore();

    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await customerStore.fetchPaymentList('null', 'null');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const validationSchema = Yup.object().shape({
      toDate: Yup.date()
      .nullable()
      .required('Date is required')
      .typeError('Invalid date format')
      .transform((value, originalValue) => {
        return originalValue === '' ? null : new Date(originalValue);
      }),
      fromDate: Yup.date()
      .nullable()
      .required('Date is required')
      .typeError('Invalid date format')
      .transform((value, originalValue) => {
        return originalValue === '' ? null : new Date(originalValue);
      }),
    })

    const { control, handleSubmit, formState: { errors }, reset, setError } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
          toDate: new Date(),
          fromDate: new Date(),
        }
    })

    const onSubmit = async (data:any) => {
      setLoading(true);
      try {
          const fromDate = new Date(data.fromDate);
          const formattedFormDate = `${fromDate.getFullYear()}-${String(fromDate.getMonth() + 1).padStart(2, '0')}-${String(fromDate.getDate()).padStart(2, '0')}`;
          const toDate = new Date(data.toDate);
          const formattedToDate = `${toDate.getFullYear()}-${String(toDate.getMonth() + 1).padStart(2, '0')}-${String(toDate.getDate()).padStart(2, '0')}`;
          const resData = await customerStore.fetchPaymentList(formattedFormDate, formattedToDate);
          console.log(resData);
          setLoading(false);
      } catch (error:any) {
          Object.keys(error?.data).map((e:any) => {
          setError(e, {
              type: 'manual', // Use 'manual' for manually triggered errors
              message: error?.data[e],
          });
          })
          setLoading(false);
      }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const confirmDelete = async(id:any)=>{
      setLoading(true);
      try {
        const resData = await customerStore.deleteData(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    const deleteData = (params:any) => {

      customerStore.rootStore.dialogStore.openDialog({
        confirmFn: () => confirmDelete(params.id),
        dialogText: t("customers.deleteConfirmText")
      })
      // customerStore.deleteDialog(params);
      // setLoading(false);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      customerStore.paymentRowData = customerStore.searchPaymentData.filter((row)=>{
        return row.name.toLowerCase().includes(value)
         || row.phone_number.toLowerCase().includes(value)
         || row.address.toLowerCase().includes(value)
         || row.type.toLowerCase().includes(value)
         || row.status.toLowerCase().includes(value)
         || row.amount.replace(/,/g, '').includes(value)
         || row.date.toLowerCase().includes(value)
         || row.time.toLowerCase().includes(value);
      })
    };

    const getButtonColor = (status:any) => {
      switch (status) {
        case "Approve":
          return "success"; // Green button
        case "Pending":
          return "warning"; // Yellow button
        default:
          return "primary"; // Default blue
      }
    };

    const handleStatusClick = async (row:any) => {
      // alert(`Status button clicked for  ${row.type} (${row.tableId}): ${row.status}`);
      setLoading(true);
      try {
        const resData = await customerStore.updatePaymentStatus(row.id, row.tableId, row.type, row.status);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Box sx={{ height: 400, width: '100%' }}>
       <h3>{t('customers.paymentList')}</h3>
       <Button variant="contained" sx={{mb:2}} component={Link} to="create"><PersonAddIcon sx={{mr:1}}/>{t('addNew')}</Button>
       <Toolbar style={{padding: 0, justifyContent:'space-between', alignItems:'center'}}>
        <Grid container alignItems="center" justifyContent={'space-between'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={t('search')}
              value={searchText}
              onChange={handleSearch}
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container alignItems="center" justifyContent={'flex-end'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="fromDate"
                    render={({ field }) => (
                        <DatePicker
                        placeholderText="Select a date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        dateFormat="dd-MM-yyyy"
                        customInput={
                            <TextField
                            variant="outlined"
                            fullWidth
                            sx={{
                                margin: 0, // Outer margin
                                '& .MuiInputBase-root': {
                                height: '40px', // Input height
                                padding: '0 0px', // Input padding
                                fontSize: '14px'
                                },
                            }}
                            />
                        }
                        />
                    )}
                  />
                  {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate.message}</p>}
                </Grid>
                <Grid item xs={1}>
                    To
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="toDate"
                    render={({ field }) => (
                        <DatePicker
                        placeholderText="Select a date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        dateFormat="dd-MM-yyyy"
                        customInput={
                            <TextField
                            variant="outlined"
                            fullWidth
                            sx={{
                                margin: 0, // Outer margin
                                '& .MuiInputBase-root': {
                                height: '40px', // Input height
                                padding: '0 0px', // Input padding
                                fontSize: '14px'
                                },
                            }}
                            />
                        }
                        />
                    )}
                  />
                  {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate.message}</p>}
                </Grid>
                <Grid item xs={2}>
                  <Button sx={{ ml: .01, mr:0 }} type="submit" variant="contained" color="success">
                      {t('search')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Toolbar>
      <DataGrid
        rows={customerStore.paymentRowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 20,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              {field: 'name', headerName: t('name'), width:150},
              {field: 'phone_number', headerName: t('phone'), width:150},
              {field: 'address', headerName: t('address'), width:200},
              {field: 'type', headerName: t('customerPaymenttype'), width:150, align:'center'},
              {field: 'amount', headerName: t('customerPaymentAmount'), width:150, align:'right'},
              {
                field: 'status',
                headerName: t('status'),
                align:'center', width:100,
                renderCell: (params) => (
                  <>
                  {authStore.role==='Admin' ? 
                  <Button
                    variant="contained"
                    size="small"
                    color={getButtonColor(params.value)}
                    onClick={() => handleStatusClick(params.row)}
                  >
                    {params.value}
                  </Button> : 
                  <Button
                    variant="contained"
                    size="small"
                    color={getButtonColor(params.value)}
                  >
                    {params.value}
                  </Button>}
                  </>
                ),
              },
              {field: 'date', headerName: t('date'), width:100},
              {field: 'time', headerName: t('time'), width:100},
              {
                  field: 'actions',
                  headerName: t('action'),
                  width:150,
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => (
                      <>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={''}><EditIcon/></Button>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}}><DeleteIcon/></Button>
                      </>
                  )
              }
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
      />
    </Box>
    </>
  );
}

export default observer(CustomerPaymentList);
