import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, Button, MenuItem, Card, CardMedia } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';


const StockEdit = () => {
    
    const [imageUrl, setImageUrl] = useState<string|null>(null)
    const [products, setProducts] = useState<any[]>([]);
    const [units, setUnits] = useState<any[]>([]);
    const { rootStore: { stockStore } } = useStore();
    const { getData, initProducts, getProductUnits, updateData} = stockStore;
    const { id } = useParams()
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [defaultUnits, setDefaultUnits] = useState<any[]>([]);
    const [preUnits, setPreUnits] = useState<any[]>([]);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        variant_id: Yup.string().required('Product is required'),
        quantity: Yup.number().required('Stock quantity is required').min(1, 'Minimum quantity is 1'),
        unit: Yup.string().required('You must select an option'),
    })

    const { control, register, handleSubmit, setValue, formState: { errors }, reset, setError } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            variant_id: "",
            quantity: 0,
            unit: "",
        }
    })

    const onSubmit = async (data:any) => {
        setLoading(true);
        try {
            if(id){
                const formData = new FormData();
                Object.keys(data).map(key => {
                    formData.append(key, data[key]);
                })
                const resData = await updateData(id, formData)
                if (resData){
                    reset()
                    navigate('..')
                }
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
                setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
                });
            })
            setLoading(false);
        }
    }
  
    const initProductsData = async () => {
        setLoading(true);
        try {
            const resData = await initProducts();
            setProducts(resData.data.products)

            if(id){
                const resData = await getData(id)
                console.log(resData.data)
                setUnits(resData.data.units);
                reset(resData.data.stock);
            }else{
                navigate(-1)
            }
            setLoading(false);
        } catch (error) {
            // Handle errors, e.g., show an error message
            setLoading(false);
            console.error('Error while fetching data:', error);
        }
    }

    const initProductUnits = async (id: number | string) => {
        try{
            setLoading(true);
            const resData = await getProductUnits(id);
            if(resData){
                setLoading(false);
                console.log(resData);
                setUnits(resData.data.units);
                setValue('unit',resData.data.default_unit);
                // setProducts(resData.data.products)
            }
            
        }catch(e: any){
            setLoading(false);
            console.log(e);
        }
    }

    useEffect(() => {
        initProductsData()
    }, [id])
    

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        
        <Box sx={{ width: '100%' }}>
            <h4>{t('stocks.update')}</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
                <Controller
                name="variant_id"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    select
                    id="variant_id"
                    label={t("product")}
                    variant="filled"
                    error={!!errors.variant_id}
                    helperText={errors.variant_id?.message}
                    size="small"
                    onChange={(e)=>{
                        const productId = e.target.value;
                        setValue('variant_id',productId);
                        initProductUnits(productId);
                    }}
                    >
                    {products.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                        {option.name}
                        </MenuItem>
                    ))}
                    </TextField>
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                name="quantity"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="quantity"
                    label={t("stock")}
                    variant="filled"
                    error={!!errors.quantity}
                    helperText={errors.quantity?.message}
                    size="small"
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <label htmlFor="">{t('unit')}</label><br/>
                {units.map((units)=>(
                    <>
                    <label>
                    <input
                        type="radio"
                        value={units.id}
                        {...register('unit')}
                    />
                    {units.text}
                    </label>
                    </>
                ))}
                {errors.unit && <p>{errors.unit.message}</p>}
            </Grid>
                </Grid>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} type="submit" variant="contained" color="success">
                {t('update')}
                </Button>
            </form>
        </Box>
        </>
    )
}

export default observer(StockEdit);