import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, FormControlLabel, Checkbox, Button, CardMedia, Card, MenuItem, InputAdornment } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import ServerSideSupplierAutocomplete from "../../../components/ui/ServerSideAutocomplete/ServerSideSupplierAutocomplete";

const SupplierPaymentCreate = () => {

    const { rootStore: { saleStore, supplierStore } } = useStore();
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [payment, setPayment] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [accounts, setAccounts] = useState<any[]>([]);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        supplier: Yup.object().shape({
            id: Yup.string().required(t('formValidation.required')),
            label: Yup.string().required(t('formValidation.required')),
        }).required(t('formValidation.required')),
        account_id: Yup.string(),
        total_purchase: Yup.number(),
        total_payment: Yup.number(),
        total_due: Yup.number(),
        total_advance: Yup.number(),
        new_payment: Yup.number().typeError(t('formValidation.numberOnly'))
        .required(t('formValidation.required'))
        .positive('Amount must be positive')
        .min(1, t('formValidation.minimumOne')),
    });

    const hookFormObj = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            supplier: {id: "", label: ""},
            account_id: "",
            total_purchase: 0,
            total_payment: 0,
            total_due: 0,
            total_advance: 0,
            new_payment: 0,
        }
    })

    const { control, handleSubmit, formState: {errors}, reset, setValue, getValues, setError } = hookFormObj

    const formatNumberWithCommas = (value:any) => {
        if (!value) return '';
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const removeCommas = (value:any) => {
        return value.replace(/,/g, '');
    };
    
    const onSubmit = async (data:any) => {
        setLoading(true);
        try {
            const resData = await supplierStore.createPaymentData(data);
            setLoading(false);
            if (resData){
                reset()
                navigate('/app/suppliers/payments')
            }
          } catch (error:any) {
            setLoading(false);
            console.log('Errors');
            Object.keys(error?.data).map((e:any) => {
              setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
              });
            })
            
          }
    }

    const handleSelectSupplier = (value: any) => {
        console.log("handleSelectSupplier ", value)
        const totalPayment = parseFloat(value?.payment) + parseFloat(value?.due_payment) + parseFloat(value?.advance_payment);
        const totalDue = parseFloat(value?.purchase) - Number(totalPayment);
        let advance = 0;
        let due = 0;
        if(totalDue < 0){
            advance = Math.abs(totalDue);
        }else if(isNaN(totalDue)){
            due = 0;
        }
        else if(totalDue > 0){
            due = totalDue;
        }
        else{
            due = 0;
        }
        setValue('supplier', value);
        setValue('total_purchase', value?.purchase);  
        setValue('total_payment', isNaN(totalPayment)?0:totalPayment);  
        setValue('total_due', due);  
        setValue('total_advance', advance); 
        setAccounts(value.accounts);
    };
    
    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        <Box sx={{ width: '100%' }}>
            <h4 style={{marginTop:0}}>{t('suppliers.newPayment')}</h4>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4.7}>
                <Controller
                    name="supplier"
                    control={control}
                    render={({ field }) => (
                    <ServerSideSupplierAutocomplete
                        label={t('selectSupplier')}
                        ajaxCallFn={supplierStore.getList} 
                        addNewFn={()=>""}
                        onOptionSelect={(option) => {
                            field.onChange(option)
                            handleSelectSupplier(option)
                            if (option?.isNew) {
                                reset({
                                    supplier: {id: "", label: ""},
                                })
                                setDialogOpen(true);
                            }

                        }}
                        error={errors.supplier?.id ?? errors.supplier}
                        field={field}
                    />
                    )}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_purchase"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_purchase"
                        label={t('totalPurchase')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_purchase}
                        helperText={errors.total_purchase?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_payment"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_payment"
                        label={t('totalPayment')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_payment}
                        helperText={errors.total_payment?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_due"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_due"
                        label={t('totalDue')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_due}
                        helperText={errors.total_due?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_advance"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_advance"
                        label={t('totalAdvance')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_advance}
                        helperText={errors.total_advance?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={4.7}>
                <Controller
                name="account_id"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    select
                    id="account_id"
                    label={t("bankAccountName")}
                    variant="filled"
                    error={!!errors.account_id}
                    helperText={errors.account_id?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            paddingleft: '8px', // Input padding
                            paddingTop:'0px',
                            fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                        },
                        }}
                    >
                    {accounts.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                        {option.name}
                        </MenuItem>
                    ))}
                    </TextField>
                )}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={4.7}>
                <Controller
                    name="new_payment"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        fullWidth
                        id="new_payment"
                        label={t('supplierNewPayment')}
                        variant="filled"
                        value={formatNumberWithCommas(value)}
                        onChange={(e) => {
                            const rawValue = removeCommas(e.target.value);
                            setValue("new_payment", rawValue, { shouldValidate: true });
                        }}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        onBlur={onBlur}
                        error={!!errors.new_payment}
                        helperText={errors.new_payment?.message}
                        />
                    )}
                    />
                </Grid>
            </Grid>
            </form>
            {/* Add new item form */}
            <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
            </Button>
            <Button sx={{ mt: 2, ml: 2 }} type="button" variant="contained" color="success" onClick={() => handleSubmit(onSubmit)()}>
                {t('save')}
            </Button>
        </Box>
        </>
    )
    
}

export default observer(SupplierPaymentCreate);