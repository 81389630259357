import React from "react";
import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./rootStore";
import { GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { Button, ListItem, ListItemButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Define your row type
interface ProductRowModel extends GridValidRowModel {
  name: string;
  category: string;
  units: string;
  purchase_price: number;
  sale_price: number;
  alert_limit: string;
}

export default class ProductStore {
    
    BASE_URL = process.env.REACT_APP_API_URL + '/v1/products';
    
    rootStore: IRootStore;
    rowData: ProductRowModel[] = [];
    searchData: ProductRowModel[] = [];
    columns: GridColDef[] = [
        // {field: 'id', headerName: 'Id', width: 100},
        
    ];

    constructor(rootStore: IRootStore) {
      makeObservable(this, {
          rowData: observable,
          columns: observable,
          setRowData: action,
          fetchList: action,
          createData: action,
          getData: action,
          updateData: action,
          initCategories: action,
          initUnits: action,
          getList: action,
          getListWithBarcode: action,
          getListForPurchase: action,
          getListWithBarcodeForPurchase: action,
      });
      this.rootStore = rootStore;
  }

  setRowData(values: ProductRowModel[]) {
      this.rowData = values;
      this.searchData = values;
  }

  //   Api Calls
  fetchList = async () => {
      try {
          const response = await fetch(this.BASE_URL + '/list', {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              }
          })
          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
            console.log(data.data.products);
              this.setRowData(data.data.products);
              return Promise.resolve(data)
          }
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  // initCategories
  initCategories = async () => {
      try {
          const response = await fetch(this.BASE_URL + '/initCategories', {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              }
          })
          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              return Promise.resolve(data)
          }
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  // initUnits
  initUnits = async () => {
    try {
        const response = await fetch(this.BASE_URL + '/initUnits', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                'Content-Type': 'application/json', // You can adjust this header as needed
            }
        })
        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
            return Promise.reject(data)
        } else {
            return Promise.resolve(data)
        }
    } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
    }
}

  // Create
  createData = async (postData: any) => {
      try {
          const response = await fetch(this.BASE_URL, {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
              },
              body: postData
          })

          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, data.message, data);
              return Promise.reject(data)
          } else {
              this.rootStore.alertStore.open({status: "success", message: data.message})
              return Promise.resolve(data)
          }
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }


  
  // View
  getData = async (id: number | string) => {
    try {

      const response = await fetch(this.BASE_URL + `/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })
      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }


  // Update
  updateData = async (id: number | string, postData: any) => {
    try {
      const response = await fetch(this.BASE_URL + `/${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
        },
        body: postData
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status,  data.message, data);
        return Promise.reject(data)
      } else {
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Delete
  deleteData = async (id: number | string) => {
    try {
      const response = await fetch(this.BASE_URL + `/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setRowData(this.rowData.filter((e:any) => e.id != id))
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  //   Api Calls
  getList = async (postData:any) => {
    try {
      
          const response = await fetch(this.BASE_URL + '/getList', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              },
              body: JSON.stringify(postData)
          })
          const data = await response.json();
        //   console.log(data);
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              return Promise.resolve(data.data.products)
          }
          
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  getListWithBarcode = async (postData:any) => {
    try {
      
          const response = await fetch(this.BASE_URL + '/getListWithBarcode', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              },
              body: JSON.stringify(postData)
          })
          const data = await response.json();
          
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              return Promise.resolve(data.data.products)
          }
          
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  //   Api Calls
  getListForPurchase = async (postData:any) => {
    try {
      
          const response = await fetch(this.BASE_URL + '/getListForPurchase', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              },
              body: JSON.stringify(postData)
          })
          const data = await response.json();
          
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              return Promise.resolve(data.data.products)
          }
          
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  getListWithBarcodeForPurchase = async (postData:any) => {
    try {
      
          const response = await fetch(this.BASE_URL + '/getListWithBarcodeForPurchase', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              },
              body: JSON.stringify(postData)
          })
          const data = await response.json();
          
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              return Promise.resolve(data.data.products)
          }
          
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  // Delete
//   deleteDialog = async (params: any) => {
//     this.rootStore.dialogStore.openDialog({
//         confirmFn: () => this.deleteData(params.id),
//         dialogText: "Are you sure you want to delete this item ?"
//     })
//   }

}